import React from 'react'
import { useRouteError } from 'react-router-dom'
import { Center, Container, Heading, Text } from '@chakra-ui/react'

export default function ErrorPage() {
    const error: any = useRouteError()
    console.error(error)

    return (
        <Center minH="100vh">
            <Container maxW="800px" textAlign="center">
                <Heading>Oops!</Heading>
                <Text my="40px">Sorry, an unexpected error has occurred.</Text>
                <Text>
                    <Text as="i">
                        {
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            error.statusText || error.message
                        }
                    </Text>
                </Text>
            </Container>
        </Center>
    )
}
