import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import CommonModal from 'components/CommonModal'
import 'react-quill/dist/quill.snow.css'
import { useCreateNotes } from 'utils/api/contacts.api'
import { showToast } from 'utils/helper'
interface NotesModalProps {
    isOpen: boolean
    onClose: () => void
}

const CreateTaskModal: React.FC<NotesModalProps> = ({
    isOpen,
    onClose,
}: NotesModalProps) => {
    const defaultValues = {
        content: undefined,
    }
    const [tabDetail, setTabDetail]: any = useState(defaultValues)
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
        ],
    }
    const { mutate, isLoading } = useCreateNotes({
        onSuccess: () => {
            setTabDetail(tabDetail)
            onClose()
            showToast('success', 'notes created successfully!')
        },
        onError: () => {
            showToast('error', 'some error has occured!')
        },
    })
    const sendNotes = async () => {
        const payload = {
            content: tabDetail.content,
        }
        mutate(payload)
    }
    return (
        <CommonModal
            isOpen={isOpen}
            onClose={onClose}
            title="Notes"
            size="2xl"
            primaryAction={{
                label: 'Save Note',
                loading: isLoading,
                onClick: () => {
                    sendNotes()
                },
            }}
        >
            <ReactQuill
                className="quill-notes"
                placeholder="start typing to leave a note..."
                // style={{ minHeight: '300px' }}
                id={`tabdetail`}
                modules={modules}
                value={tabDetail?.content}
                onChange={value => {
                    setTabDetail((prev: any) => ({
                        ...prev,
                        content: value,
                    }))
                }}
            />
        </CommonModal>
    )
}

export default CreateTaskModal
