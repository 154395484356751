export default {
    variants: {
        primary: {
            tab: {
                p: '19px 30px',
                fontSize: '18px',
                color: 'text',
                borderBottom: '1.5px solid transparent',
                _selected: {
                    color: 'primary.500',
                    borderColor: 'primary.500',
                    fontWeight: '700',
                },
            },
            tabpanels: {
                p: '28px',
                borderTop: '1px solid #CCC',
            },
            tabpanel: {
                p: '0px',
            },
        },
    },
}
