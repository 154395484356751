import React, { ReactNode } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'

interface CommonModalProps {
    isOpen: boolean
    onClose: () => void
    title?: string
    modalBodyPadding?: string
    children: ReactNode
    primaryAction?: {
        onClick: () => void
        label?: string
        loading?: boolean
    }
    secondaryAction?: {
        onClick: () => void
        label?: string
    }
    size?: string
}

const CommonModal: React.FC<CommonModalProps> = ({
    isOpen,
    onClose,
    title = '',
    children,
    primaryAction,
    secondaryAction,
    size = 'md',
    modalBodyPadding = '30px',
    ...props
}: CommonModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={size} {...props}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader display={title === '' ? 'none' : 'block'}>
                    {title}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody p={modalBodyPadding}>{children}</ModalBody>
                <ModalFooter display={title === '' ? 'none' : 'block'}>
                    {secondaryAction?.onClick && (
                        <Button
                            colorScheme="gray"
                            onClick={secondaryAction?.onClick}
                        >
                            {secondaryAction?.label || 'Cancel'}
                        </Button>
                    )}
                    {primaryAction?.onClick && (
                        <Button
                            ml={3}
                            colorScheme="blue"
                            onClick={primaryAction?.onClick}
                            isLoading={primaryAction?.loading}
                        >
                            {primaryAction?.label || 'Submit'}
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CommonModal
