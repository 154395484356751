import { FormControl } from '@chakra-ui/form-control'
import { Box, Flex, Text } from '@chakra-ui/layout'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Select } from '@chakra-ui/select'
import { format } from 'date-fns'
import { useGetContactList } from 'utils/api/contacts.api'
import { removeHtmlTags } from 'utils/helper'

const SingleTask = ({ item }: any) => {
    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        getValues,
    } = useForm<any>({})

    const { data: contactList, isLoading: contactListLoading } =
        useGetContactList({
            onSuccess: (data: any) => {
                reset({
                    ...getValues(),
                    assignTo: item?.assignTo,
                    type: item?.type,
                    priority: item?.priority,
                })
            },
        })

    console.log('task assigned', item)

    return (
        <Box
            backgroundColor="#fdfdfd"
            marginTop="20px"
            border="1px solid #ccc"
            padding="10px"
            borderRadius="5px"
            boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
        >
            <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                    <Box>
                        <Text paddingRight="5px">
                            Task - Assigned to{' '}
                            <span>
                                {item?.assignTo.firstName}{' '}
                                {item?.assignTo.lastName}
                            </span>
                        </Text>
                    </Box>
                    <Box>
                        <Text fontSize="16px" textColor="#666">
                            {item.from}
                        </Text>
                    </Box>
                </Flex>
                <Flex>
                    <Flex gap={1} paddingLeft="5px">
                        <Text>Due:</Text>
                        <Text fontSize="16px" textColor="#666">
                            {format(new Date(item.dueDate), 'MMMM d, yyyy')}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <Box mt={2}>
                <Text>{item?.title}</Text>
            </Box>

            <Flex gap={'20px'} mt={2}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        To-do
                    </Text>

                    <FormControl>
                        <Controller
                            control={control}
                            name="type"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        border={0}
                                        placeholder=""
                                        w="80px"
                                        maxW="200px"
                                        _focusVisible={{
                                            border: 0,
                                        }}
                                        color="#00557f"
                                        value={value}
                                        onChange={onChange}
                                        isDisabled={true}
                                    >
                                        <option value={'todo'}>todo</option>
                                        <option value={'call'}>call</option>
                                        <option value={'email'}>email</option>
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        Priority
                    </Text>

                    <FormControl>
                        <Controller
                            control={control}
                            name="priority"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        border={0}
                                        placeholder=""
                                        w="80px"
                                        maxW="200px"
                                        _focusVisible={{
                                            border: 0,
                                        }}
                                        color="#00557f"
                                        value={value}
                                        onChange={onChange}
                                        isDisabled={true}
                                    >
                                        <option value={'low'}>low</option>
                                        <option value={'medium'}>medium</option>
                                        <option value={'high'}>high</option>
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        Assigned To
                    </Text>
                    <Text fontSize="16px" textColor="#666">
                        {item.assignTo.email}
                    </Text>
                </Box>
            </Flex>

            <Box marginTop="10px" paddingX="18px">
                <Text fontSize="16px" textColor="#666">
                    {removeHtmlTags(item.description)}
                </Text>
            </Box>
        </Box>
    )
}

export default SingleTask
