import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
// import { formatDate } from 'utils/formatDateTime'
import { removeHtmlTags } from 'utils/helper'

const EmailCard = ({ item, index }: any) => {
    const [showAllContent, setShowAllContent] = useState(false)

    const toggleShowAllContent = () => {
        setShowAllContent(!showAllContent)
    }

    // @ts-ignore
    const truncatedContent = removeHtmlTags(item.content)?.slice(0, 200)
    return (
        <>
            <Box
                key={index}
                backgroundColor="#fdfdfd"
                marginTop="20px"
                border="1px solid #ccc"
                padding="10px"
                borderRadius="5px"
                boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Box>
                            <Text paddingRight="5px">
                                Email - <span>{item?.subject}</span>
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="16px" textColor="#666">
                                {item.from}
                            </Text>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box paddingLeft="5px">
                            <Text fontSize="16px" textColor="#666">
                                {item.createdAt}
                            </Text>
                            <Text
                                fontSize="16px"
                                textColor="#666"
                                float="inline-end"
                            >
                                To: {item.to}
                            </Text>
                        </Box>
                    </Flex>
                </Flex>
                <Box marginY="8px">
                    <Flex width="100%">
                        <Box width="5%">
                            <Image
                                width="fill-available"
                                height="fill-available"
                                borderRadius="100%"
                                src={
                                    'https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png'
                                }
                                alt="Dan Abramov"
                            />
                        </Box>
                        <Box paddingLeft="10px" width="80%">
                            <Text fontSize="16px">{item.senderName ?? ''}</Text>
                            <Flex alignItems="center">
                                <Text
                                    width="8px"
                                    height="8px"
                                    rounded="full"
                                    backgroundColor="green"
                                ></Text>
                                <Text
                                    fontSize="13px"
                                    textColor="#666"
                                    paddingLeft="6px"
                                >
                                    SENT
                                </Text>
                            </Flex>
                            <Text
                                fontSize="14px"
                                textColor="#666"
                                maxH="200px"
                                overflow="scroll"
                            >
                                {showAllContent
                                    ? removeHtmlTags(item.content)
                                    : truncatedContent}
                                {item.content.length > 200 && (
                                    <Button
                                        onClick={toggleShowAllContent}
                                        variant="link"
                                        fontSize="14px"
                                        color="blue"
                                    >
                                        {showAllContent
                                            ? 'Show Less'
                                            : 'Show More'}
                                    </Button>
                                )}
                            </Text>
                        </Box>
                    </Flex>
                </Box>
                <Box marginTop="10px" paddingX="18px">
                    <Text fontSize="16px" textColor="#666">
                        {item.message}
                    </Text>
                </Box>
            </Box>
        </>
    )
}
export default EmailCard
