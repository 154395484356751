import React from 'react'

export const RightLongArrow = () => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 4H13M13 4L10 1M13 4L10 7"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const LeftLongArrow = () => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 4H1M1 4L4 1M1 4L4 7"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const Logo = () => (
    <svg
        width="112"
        height="80"
        viewBox="0 0 112 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M45.6996 35.3965V51.6617L45.6105 51.6999L14.7606 22.1717V5.68115L45.6996 35.3965Z"
            fill="#EEEEEE"
        />
        <path
            d="M94.5907 15.1597V31.3568L45.6995 51.6617V35.3965L45.7079 35.405L94.5907 15.1597Z"
            fill="white"
        />
        <path
            d="M112 28.3632V51.0834L43.5564 79.5103V56.7092L112 28.3632Z"
            fill="white"
        />
        <path
            d="M43.5565 56.7092V79.5102L43.2764 79.625L0 38.203V15.066L43.4165 56.7687L43.5565 56.7092Z"
            fill="#EEEEEE"
        />
        <path
            d="M79.1299 6.20435V16.8097L47.1172 30.1025V19.4546L47.1257 19.4631L79.1299 6.20435Z"
            fill="white"
        />
        <path
            d="M47.1173 19.4544V30.1023L47.0621 30.1278L26.8647 10.7967V0L47.1173 19.4544Z"
            fill="#EEEEEE"
        />
    </svg>
)
