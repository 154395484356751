import { Search2Icon } from '@chakra-ui/icons'
import {
    Box,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    VStack,
} from '@chakra-ui/react'
import React from 'react'

export default function DealStageTable() {
    return (
        <Box>
            <InputGroup w="20%" marginBottom="30px">
                <Input
                    borderRadius="10px"
                    backgroundColor="#fff"
                    border="1px solid #ccc"
                    placeholder="Serach"
                />
                <InputRightElement>
                    <Search2Icon />
                </InputRightElement>
            </InputGroup>
            <HStack justifyContent="space-between">
                <HStack
                    h="280px"
                    w="60%"
                    borderRadius="20px"
                    border="1px solid #CCC"
                    background="#FFF"
                    paddingY="20px"
                    paddingX="40px"
                    alignItems="start"
                    flexDirection="column"
                >
                    <Text
                        paddingBottom="26px"
                        color="#00557F"
                        fontSize=" 20px"
                        fontStyle="normal"
                        fontWeight=" 700"
                    >
                        All Teams
                    </Text>
                    <HStack
                        justifyContent="space-between"
                        alignItems="start"
                        spacing="60px"
                        paddingLeft="10px"
                    >
                        <VStack>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                Goal
                            </Text>
                            <Text
                                color="#00557F"
                                fontSize=" 50px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                -
                            </Text>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                September 2023
                            </Text>
                        </VStack>
                        <VStack>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                Closed Won
                            </Text>
                            <Text
                                color="#00557F"
                                fontSize=" 50px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                $0
                            </Text>
                        </VStack>
                        <VStack>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                GAP
                            </Text>
                            <Text
                                color="#00557F"
                                fontSize=" 50px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                $0
                            </Text>
                        </VStack>
                        <VStack>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                Forecast Submission
                            </Text>
                            <Text
                                color="#00557F"
                                fontSize=" 50px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                $0
                            </Text>
                        </VStack>
                    </HStack>
                </HStack>
                <HStack
                    h="280px"
                    w="38%"
                    borderRadius="20px"
                    border="1px solid #CCC"
                    background="#FFF"
                    paddingY="20px"
                    paddingX="40px"
                    alignItems="start"
                    flexDirection="column"
                >
                    <Text
                        paddingBottom="26px"
                        color="#00557F"
                        fontSize=" 20px"
                        fontStyle="normal"
                        fontWeight=" 700"
                    >
                        Sales Pipeline
                    </Text>
                    <HStack
                        justifyContent="space-between"
                        alignItems="start"
                        spacing="60px"
                        paddingLeft="40px"
                    >
                        <VStack>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                Pipeline Value
                            </Text>
                            <Text
                                color="#00557F"
                                fontSize=" 50px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                $0
                            </Text>
                        </VStack>
                        <VStack>
                            <Text
                                color="#333"
                                fontSize=" 24px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                Pipeline Coverage
                            </Text>
                            <Text
                                color="#00557F"
                                fontSize=" 50px"
                                fontStyle="normal"
                                fontWeight=" 400"
                            >
                                -
                            </Text>
                        </VStack>
                    </HStack>
                </HStack>
            </HStack>
            {/* <Box
                marginTop="30px"
                borderRadius="20px"
                border="1px solid #CCC"
                background="#FFF"
                padding="20px"
            >
                <Table border="1px solid #ccc">
                    <Thead
                        borderRadius="10px"
                        background="rgba(0, 85, 127, 0.05)"
                    >
                        <Tr
                            color=" #666"
                            fontSize="16px"
                            fontStyle=" normal"
                            fontWeight=" 700"
                            lineHeight=" normal"
                        >
                            <Th>Name</Th>
                            <Th>Goal Attainment</Th>
                            <Th>Total</Th>
                            <Th>Coverage Ratio</Th>
                            <Th>Appointment Scheme</Th>
                            <Th>Forecast Submission</Th>
                        </Tr>
                    </Thead>
                </Table>
            </Box> */}
        </Box>
    )
}
