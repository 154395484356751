import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
import { FiPhone } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useGetContactList } from '../../../../../../../utils/api/contacts.api'
import { useFilteredContacts, useDialValue } from './utility'

const ContactList = () => {
    const { data } = useGetContactList()
    const contacts = useFilteredContacts(data)
    const { phoneFormat } = useDialValue()

    return (
        <Box>
            {contacts?.map(contact => {
                const fullName = `${contact.firstName} ${contact.lastName}`
                return (
                    <Box
                        className={'list-item'}
                        key={contact.id}
                        borderBottom="1px solid #9AA9BB"
                        p="1rem 2rem"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="100%"
                        flexWrap="wrap"
                    >
                        <Box
                            className={'person'}
                            display="flex"
                            alignItems="center"
                        >
                            <Image
                                className={'avatar'}
                                src={`https://ui-avatars.com/api/?background=random&color=000&name=${fullName}`}
                                alt={fullName}
                                borderRadius="5rem"
                                width="50px"
                                mr="1rem"
                            />
                            <Box>
                                <Text
                                    className={'name'}
                                    color="cornsilk"
                                    fontSize="2rem"
                                >
                                    {fullName}
                                </Text>
                                <Text
                                    className={'phone'}
                                    color="cornsilk"
                                    fontSize="1.4rem"
                                >
                                    {phoneFormat(contact.phone)}
                                </Text>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box marginLeft="7px" cursor="pointer">
                                <Link to={`tel:${contact.phone}`}>
                                    <FiPhone size={25} color="cornsilk" />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                )
            })}
            {contacts.length === 0 && (
                <Text
                    color="cornsilk"
                    fontSize="2.5rem"
                    mt="2rem"
                    display="flex"
                    justifyContent="center"
                >
                    No contacts found
                </Text>
            )}
        </Box>
    )
}

export default ContactList
