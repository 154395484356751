import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React from 'react'
import CreateCallsModal from './components/CreateCallsModal'
import { createCallModalAtom } from 'store/modals'

const CallsTab = () => {
    const [callsModal, setCallsModal] = useAtom(createCallModalAtom)
    return (
        <Box>
            <Flex justifyContent="end">
                <Button
                    onClick={() => {
                        setCallsModal({
                            status: true,
                            data: '',
                        })
                    }}
                >
                    Make a phone call
                </Button>
            </Flex>
            <Flex marginY="15px" justifyContent="center">
                <Text fontSize="16px" textColor="#666">
                    {' '}
                    Call a contact from this record. Or log call activity to
                    keep track of your discussions and notes.{' '}
                    <span style={{ color: '#00557F' }}>Learn more</span>
                </Text>
            </Flex>
            <CreateCallsModal
                isOpen={callsModal.status}
                onClose={() => {
                    setCallsModal({
                        status: false,
                        data: '',
                    })
                }}
            />
        </Box>
    )
}

export default CallsTab
