import {
    Button,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { showToast } from '../../../../../../utils/helper'
import EmailModal from './EmailModal'
// import EmailCard from './EmailCard'
import GmailTab from './GmailTabComponent'
import OutlookTab from './OutlookTabComponent'
import { emailModalAtom } from 'store/modals'
import { useGetEmail } from 'utils/api/contacts.api'
import { userAtom } from 'store'
const EmailTab = () => {
    const { id } = useParams()
    const [emailModal, setEmailModal] = useAtom(emailModalAtom)
    const [userDetials] = useAtom(userAtom)
    const { data: emailList } = useGetEmail()

    return (
        <TabPanel>
            <Flex justifyContent="end">
                <Button
                    onClick={() => {
                        userDetials?.gmailAccounts?.length === 0
                            ? showToast(
                                  'info',
                                  'Kindly connect gmail or outlook Accounts',
                              )
                            : setEmailModal({
                                  status: true,
                                  data: '',
                              })
                    }}
                >
                    Create Email
                </Button>
            </Flex>
            <Tabs variant="enclosed-colored" colorScheme="teal">
                <TabList>
                    <Tab>Gmail</Tab>
                    <Tab>Outlook</Tab>
                </TabList>
                <TabPanels>
                    {/* Sent Tab */}
                    <TabPanel>
                        <GmailTab />
                    </TabPanel>

                    {/* Inbox Tab */}
                    <TabPanel>
                        <OutlookTab />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {/*{emailList?.map((item: any, index: number) => {*/}
            {/*    return <EmailCard item={item} index={index} />*/}
            {/*})}*/}
            <EmailModal
                isOpen={emailModal.status}
                onClose={() => {
                    setEmailModal({
                        status: false,
                        data: '',
                    })
                }}
            />
        </TabPanel>
    )
}

export default EmailTab
