import React from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import ConnectAccounts from '../views/connectAccounts'
import PrivateRoute from './PrivateRoute'
import Dashboard from 'views/dashboard'
import ErrorPage from 'views/errorPage'
// import Home from 'views/home'
import Contacts from 'views/contacts'
import ContactDetail from 'views/contactDetail'
import Emailing from 'views/emailing'
import EmailDetail from 'views/emailDetail'
import Login from 'views/login'
import { Fundraising } from 'views/fundraising'
import { Deals } from 'views/deals'
import { Forecast } from 'views/forecast'
import { Tasks } from 'views/tasks'
import { CallOutcome } from 'views/callOutcome'
import { Chats } from 'views/chats'
import { CompleteActivities } from 'views/completeActivities'

const token = localStorage.getItem('token')
const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/login" />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/login',
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/dashboard',
        element: (
            <PrivateRoute isAuthenticated={Boolean(token)}>
                <Dashboard />
            </PrivateRoute>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'connectAccounts',
                element: <ConnectAccounts />,
            },
            {
                path: 'contacts',
                element: <Contacts />,
            },
            {
                path: 'contact/:id',
                element: <ContactDetail />,
            },
            {
                path: 'emailing',
                element: <Emailing />,
            },
            {
                path: 'email/:id',
                element: <EmailDetail />,
            },
            {
                path: 'fundraising',
                element: <Fundraising />,
            },
            {
                path: 'deals',
                element: <Deals />,
            },
            {
                path: 'forecast',
                element: <Forecast />,
            },
            {
                path: 'tasks',
                element: <Tasks />,
            },
            {
                path: 'callOutcomes',
                element: <CallOutcome />,
            },
            {
                path: 'chats',
                element: <Chats />,
            },
            {
                path: 'completedActivities',
                element: <CompleteActivities />,
            },
        ],
    },
])

export default function AppRoutes() {
    return <RouterProvider router={router} />
}
