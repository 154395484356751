import React from 'react'
import { Box, Grid, Text } from '@chakra-ui/react'
import { useDialValue, dialConfig } from './utility'

const NumberPad = () => {
    const { handleAppendDialValue } = useDialValue()

    return (
        <Grid
            templateColumns="repeat(3, 1fr)"
            mx="auto"
            textAlign="center"
            backgroundColor="rgba(255,255,255,0.1)"
            width="100%"
        >
            {dialConfig.map((config, index) => (
                <Box
                    key={index}
                    color="cornsilk"
                    borderBottom="1px solid rgba(255,255,255,0.5)"
                    borderRight="1px solid rgba(255,255,255,0.5)"
                    padding=".5rem"
                    cursor="pointer"
                    flex="1 0 33.3%"
                    _hover={{
                        backgroundColor: 'rgba(255,255,255,0.05)',
                    }}
                    textAlign="center"
                    display="inline-block"
                    onClick={() => {
                        handleAppendDialValue(config[0])
                    }}
                >
                    <Text fontSize="2rem" minH="2.4rem" lineHeight="2.4rem">
                        {config[0]}
                    </Text>
                    <Text fontSize=".8rem" minH="1rem" lineHeight="1rem">
                        {config[1] || '\u200b'}
                    </Text>
                </Box>
            ))}
        </Grid>
    )
}

export default NumberPad
