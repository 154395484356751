import { Box, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useGetDealsInfo } from 'utils/api/deals.api'
export default function AllDealsTable() {
    const { data, isLoading } = useGetDealsInfo()

    return (
        <Box>
            {isLoading ? (
                <Spinner />
            ) : (
                <HStack
                    width="100%"
                    borderRadius="20px"
                    border="1px solid #CCC"
                    background="#FFF"
                    paddingY="50px"
                    paddingX="40px"
                    justifyContent="space-between"
                    alignItems="start"
                >
                    <VStack>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Total Deal Amount
                        </Text>
                        <Text
                            color="#00557F"
                            fontSize=" 45px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            $ {data?.totalDealAmount.toLocaleString()}
                        </Text>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Avg ${data?.averageDealAmount.toLocaleString()} per
                            deal
                        </Text>
                    </VStack>
                    {/* <VStack>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Weighted Deal Amount
                        </Text>
                        <Text
                            color="#00557F"
                            fontSize=" 50px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            $202.5 M
                        </Text>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Avg $36,834,768 per deal
                        </Text>
                    </VStack> */}
                    <VStack>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Open Deal Amount
                        </Text>
                        <Text
                            color="#00557F"
                            fontSize=" 45px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            ${data?.openDealAmount.toLocaleString()}
                        </Text>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Avg ${data?.averageOpenDeal.toLocaleString()} per
                            deal
                        </Text>
                    </VStack>
                    <VStack>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Closed Won
                        </Text>
                        <Text
                            color="#00557F"
                            fontSize=" 45px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            ${data?.closeWon.toLocaleString()}
                        </Text>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            Avg ${data?.averageCloseWon.toLocaleString()} per
                            deal
                        </Text>
                    </VStack>
                    <VStack>
                        <Text
                            color="#333"
                            fontSize=" 24px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            New Deal Amount
                        </Text>
                        <Text
                            color="#00557F"
                            fontSize=" 45px"
                            fontStyle="normal"
                            fontWeight=" 400"
                        >
                            $ {data?.dealAmountThisWeek.toLocaleString()}
                        </Text>
                    </VStack>
                </HStack>
            )}
        </Box>
    )
}
