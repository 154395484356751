import { useToken } from '@chakra-ui/react'
import React from 'react'
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'

const CompleteActivitiesChart = ({ data }: any) => {
    const [primaryColor] = useToken('colors', ['#BDABE9'])
    const CustomTick = () => null

    return (
        <ResponsiveContainer aspect={2.5}>
            <BarChart
                layout="vertical"
                width={500}
                height={500}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid horizontal={false} />
                <YAxis dataKey="name" type="category" reversed />
                <XAxis tick={<CustomTick />} />
                <Tooltip />
                <Bar
                    barSize={40}
                    dataKey="value"
                    fill={primaryColor}
                    activeBar={<Rectangle fill="pink" stroke="#BDABE9" />}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default CompleteActivitiesChart
