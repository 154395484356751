import React, { useState } from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { createColumnHelper } from '@tanstack/react-table'
import { SearchIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import useGmailHookLogic from '../contactDetail/components/Activity/components/EmailTab/GmailTabComponent/useGmailHookLogic'
import useOutlookHookLogic from '../contactDetail/components/Activity/components/EmailTab/OutlookTabComponent/useOutlookHookLogic'
import SentBox from './components/sentBox'
import DataTable from 'components/table'
import { emailModalAtom } from 'store/modals'
import EmailModal from 'views/contactDetail/components/Activity/components/EmailTab/EmailModal'
export default function Emailing() {
    const [searchText, setSearchText] = useState('')
    const [emailModal, setEmailModal] = useAtom(emailModalAtom)
    const filteredInboxGmail = useGmailHookLogic('INBOX')
    const filteredInboxOutlook = useOutlookHookLogic('Inbox')
    const combinedInboxes =
        [...filteredInboxGmail, ...filteredInboxOutlook] || []
    // const { data } = useGetEmailList()

    const renderTable = (type: string) => {
        return (
            <DataTable
                isHeader
                columns={columns}
                data={combinedInboxes}
                emptyData={
                    <Text
                        display="block"
                        m="20px 0 0"
                        textAlign="center"
                        fontSize="26px"
                        as="strong"
                    >
                        No Data Found
                    </Text>
                }
            />
        )
    }

    return (
        <>
            <Flex justify="space-between" mb="35px">
                <Box>
                    <Heading as="h1">Email</Heading>
                    <Text as="span" fontSize="24px">
                        {combinedInboxes?.length} Mails
                    </Text>
                </Box>
                <Flex></Flex>
            </Flex>
            <Tabs variant="primary">
                <TabList>
                    <Tab>Sent</Tab>
                    <Tab>Inbox</Tab>
                    <Tab>Drafts</Tab>
                    <Tab>Archive</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box boxShadow="5px 0px 20px 0px rgba(187, 187, 187, 0.20)">
                            <SentBox />
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box boxShadow="5px 0px 20px 0px rgba(187, 187, 187, 0.20)">
                            <Flex
                                justify="space-between"
                                border="1px solid #CCC"
                                borderBottom="none"
                                bg="white"
                                p="20px"
                                rounded="20px 20px 0 0"
                            >
                                <InputGroup maxW="300px">
                                    <Input
                                        variant="primary"
                                        onChange={e => {
                                            setSearchText(e.target.value)
                                        }}
                                        placeholder="Search"
                                    />
                                    <InputRightElement>
                                        <SearchIcon />
                                    </InputRightElement>
                                </InputGroup>
                                <Button
                                    onClick={() => {
                                        setEmailModal({
                                            status: true,
                                            data: '',
                                        })
                                    }}
                                >
                                    Create Email
                                </Button>
                            </Flex>

                            {renderTable('inbox')}
                        </Box>
                        <EmailModal
                            isOpen={emailModal.status}
                            onClose={() => {
                                setEmailModal({
                                    status: false,
                                    data: '',
                                })
                            }}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Box boxShadow="5px 0px 20px 0px rgba(187, 187, 187, 0.20)">
                            {renderTable('drafts')}
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box boxShadow="5px 0px 20px 0px rgba(187, 187, 187, 0.20)">
                            {renderTable('archive')}
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

const columnHelper = createColumnHelper<any>()
const columns = [
    columnHelper.accessor('senderName', {
        header: 'Name',
    }),
    columnHelper.accessor('subject', {
        header: 'Subject',
    }),
    columnHelper.accessor('createdAt', {
        cell: info => `${new Date(info.getValue()).toLocaleDateString()}`,
        header: 'Date',
    }),
    columnHelper.accessor('_id', {
        header: '',
        cell: info => (
            <ButtonGroup w="max-content">
                <Button
                    as={Link}
                    to={`/dashboard/email/${info?.getValue()}`}
                    w="120px"
                >
                    View
                </Button>
                <Button w="120px">Delete</Button>
            </ButtonGroup>
        ),
    }),
]
