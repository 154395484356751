import React from 'react'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardBody,
    Divider,
    Flex,
    Heading,
    Text,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatGroup,
    Grid,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetEmail } from 'utils/api/emails.api'

export default function EmailDetail() {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data } = useGetEmail(id)
    return (
        <>
            <Flex justify="space-between">
                <Heading fontSize="24px">{data?.subject}</Heading>
                <Button
                    w="140px"
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    Back
                </Button>
            </Flex>
            <Flex mt="40px" gap="90px">
                <Avatar w="250px" h="250px" rounded="20px" />
                <Flex
                    flexWrap="wrap"
                    maxW="600px"
                    w="100%"
                    gap="60px"
                    alignContent="flex-start"
                >
                    <Box flex="calc(50% - 30px)">
                        <Heading as="h6" variant="primary" fontSize="18px">
                            Subject
                        </Heading>
                        <Text>{data?.subject}</Text>
                    </Box>
                    <Box flex="calc(50% - 30px)">
                        <Heading as="h6" variant="primary" fontSize="18px">
                            Included List
                        </Heading>
                        <Text>Catch up on our latest blog posts! </Text>
                    </Box>
                    <Box flex="calc(50% - 30px)">
                        <Heading as="h6" variant="primary" fontSize="18px">
                            Sent Date
                        </Heading>
                        <Text>
                            {new Date(data?.createdAt ?? '').toUTCString()}
                        </Text>
                    </Box>
                    <Box flex="calc(50% - 30px)">
                        <Heading as="h6" variant="primary" fontSize="18px">
                            Email Type
                        </Heading>
                        <Text>Catch up on our latest blog posts! </Text>
                    </Box>
                </Flex>
            </Flex>
            <Divider bg="border" my="40px" />
            <Text as="strong" variant="secondary" fontSize="24px">
                Sent to 42,257
            </Text>
            <Grid
                my="30px"
                gap="30px"
                gridTemplateColumns="repeat( auto-fit, minmax(280px, 1fr) );"
            >
                <Card>
                    <CardBody>
                        <Text as="span" fontSize="24px" variant="secondary">
                            Open Rate 6.88%
                        </Text>
                        <Slider
                            aria-label="slider-ex-1"
                            h="70px"
                            defaultValue={30}
                            my="20px"
                            pointerEvents="none"
                        >
                            <SliderTrack h="70px" rounded="10px">
                                <SliderFilledTrack />
                            </SliderTrack>
                        </Slider>
                        <Flex justify="space-between">
                            <Text fontSize="24px">Unique opens</Text>
                            <Text fontSize="24px">945</Text>
                        </Flex>
                        <Divider bg="border" my="20px" />
                        <Flex justify="space-between">
                            <Text fontSize="24px">Total opens</Text>
                            <Text fontSize="24px">945</Text>
                        </Flex>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Text as="span" fontSize="24px" variant="secondary">
                            Click Rate 69.88%
                        </Text>
                        <Slider
                            aria-label="slider-ex-1"
                            h="70px"
                            defaultValue={30}
                            my="20px"
                            pointerEvents="none"
                        >
                            <SliderTrack h="70px" rounded="10px">
                                <SliderFilledTrack />
                            </SliderTrack>
                        </Slider>
                        <Flex justify="space-between">
                            <Text fontSize="24px">Unique clicks</Text>
                            <Text fontSize="24px">945</Text>
                        </Flex>
                        <Divider bg="border" my="20px" />
                        <Flex justify="space-between">
                            <Text fontSize="24px">Total clicks</Text>
                            <Text fontSize="24px">945</Text>
                        </Flex>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Text as="span" fontSize="24px" variant="secondary">
                            Reply Rate 6.88%
                        </Text>
                        <Slider
                            aria-label="slider-ex-1"
                            h="70px"
                            defaultValue={30}
                            my="20px"
                            pointerEvents="none"
                        >
                            <SliderTrack h="70px" rounded="10px">
                                <SliderFilledTrack />
                            </SliderTrack>
                        </Slider>
                        <Flex justify="space-between">
                            <Text fontSize="24px">Unique replies</Text>
                            <Text fontSize="24px">945</Text>
                        </Flex>
                        <Divider bg="border" my="20px" />
                        <Flex justify="space-between">
                            <Text fontSize="24px">Total replies</Text>
                            <Text fontSize="24px">945</Text>
                        </Flex>
                    </CardBody>
                </Card>
            </Grid>
            <Heading as="h3" fontSize="24px">
                Delivery
            </Heading>
            <Card mt="30px">
                <CardBody>
                    <StatGroup>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">
                                Successful Deliveries
                            </StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                34,567
                            </StatNumber>
                            <StatHelpText fontSize="24px">23.36%</StatHelpText>
                        </Stat>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">Bounces</StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                70
                            </StatNumber>
                            <StatHelpText fontSize="24px">23.36%</StatHelpText>
                        </Stat>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">UnSubscribes</StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                30
                            </StatNumber>
                            <StatHelpText fontSize="24px">23.36%</StatHelpText>
                        </Stat>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">Spam Reports</StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                34
                            </StatNumber>
                            <StatHelpText fontSize="24px">23.36%</StatHelpText>
                        </Stat>
                    </StatGroup>
                </CardBody>
            </Card>
            <Heading as="h3" fontSize="24px" my="30px">
                Time Spent Viewing Email
            </Heading>
            <Card>
                <CardBody>
                    <StatGroup>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">Read</StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                34%
                            </StatNumber>
                        </Stat>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">Skimmed</StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                7%
                            </StatNumber>
                        </Stat>
                        <Stat textAlign="center">
                            <StatLabel fontSize="24px">Glanced</StatLabel>
                            <StatNumber color="primary.500" fontSize="50px">
                                3%
                            </StatNumber>
                        </Stat>
                    </StatGroup>
                </CardBody>
            </Card>
        </>
    )
}
