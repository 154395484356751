import React from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import ArchiveTab from './ArchiveTab'
import InboxTab from './InboxTab'
import SentTab from './SentTab'

const OutlookTab = () => {
    return (
        <TabPanel>
            {/* Sub-tabs for Sent, Inbox, and Archive */}
            <Tabs variant="enclosed-colored" colorScheme="teal">
                <TabList>
                    <Tab>Sent</Tab>
                    <Tab>Inbox</Tab>
                    <Tab>Archive</Tab>
                </TabList>
                <TabPanels>
                    {/* Sent Tab */}
                    <TabPanel>
                        <SentTab />
                    </TabPanel>

                    {/* Inbox Tab */}
                    <TabPanel>
                        <InboxTab />
                    </TabPanel>

                    {/* Archive Tab */}
                    <TabPanel>
                        <ArchiveTab />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </TabPanel>
    )
}

export default OutlookTab
