export default {
    baseStyle: {
        container: {
            rounded: '20px',
            border: '1px solid #CCC',
            background: 'white',
            boxShadow: '5px 0px 20px 0px rgba(187, 187, 187, 0.20)',
        },
        header: {
            p: '20px',
        },
    },
}
