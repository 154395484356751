import React, { useEffect, useState } from 'react'
import { ReactMultiEmail } from 'react-multi-email'
import { Box, Flex, FormControl, Input, Select, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactQuill from 'react-quill'
import { useParams } from 'react-router-dom'
import ConnectWithAccounts from '../../../../../connectAccounts/components/ConnectWithAccounts'
import CommonModal from 'components/CommonModal'
import useQueryCacheValue from 'utils/hooks/useQueryCacheValue'
import { userAtom } from 'store'
import { isAccessTokenValid, parseJwt, showToast } from 'utils/helper'
import { useCreateEmail } from 'utils/api/contacts.api'
interface EmailModalProps {
    isOpen: boolean
    onClose: () => void
}
const EmailModal: React.FC<EmailModalProps> = ({
    isOpen,
    onClose,
}: EmailModalProps) => {
    const [mail, setMail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [userDetials] = useAtom(userAtom)
    const { id } = useParams()
    const contactDetail = useQueryCacheValue(['getContact', id])
    const { firstName, lastName, email } = contactDetail ?? {}
    const validationSchema = Yup.object().shape({
        to: Yup.array().required(),
    })

    const defaultValues = {
        to: [],
        message: '',
        from: '',
        subject: '',
    }

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm<any>({
        defaultValues,
        resolver: yupResolver(validationSchema) as any,
        mode: 'all',
    })

    const to = watch('to')
    const message = watch('message')
    const from = watch('from')
    const subject = watch('subject')

    const { mutate: saveEmail, isLoading: savingEmail } = useCreateEmail({
        onSuccess: () => {
            setIsLoading(false)
            onClose()
            reset(defaultValues)
            showToast('success', 'email saved successfully!')
        },
        onError: () => {
            setIsLoading(false)
            showToast(
                'error',
                'some error has occured while saving email on database!',
            )
        },
    })

    const sendEmailUsingMicrosoftGraphAPI = async (
        accessToken: string,
        toEmails: string[],
        subject: string,
        message: string,
        from: string,
    ) => {
        try {
            setIsLoading(true)

            // Array to store promises of saveEmail calls
            const saveEmailPromises = []

            // Iterate over each email address
            for (const toEmail of toEmails) {
                const trimmedEmail = toEmail.trim()

                // Create the email message object
                const emailMessage = {
                    toRecipients: [{ emailAddress: { address: trimmedEmail } }],
                    subject: subject ?? 'testing',
                    body: {
                        content: message,
                        contentType: 'text',
                    },
                    from: { emailAddress: { address: from } },
                }

                console.log('accessToken*', accessToken)

                // Make a request to the Microsoft Graph API to send the email
                const response = await fetch(
                    'https://graph.microsoft.com/v1.0/me/sendmail',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            message: emailMessage, // Wrap emailMessage inside 'message'
                            saveToSentItems: 'true',
                        }),
                    },
                )

                if (!response.ok) {
                    const data = await response.json()
                    console.log({ response }, data)
                    console.error('Failed to send email:', response.statusText)
                    setIsLoading(false)
                    showToast(
                        'error',
                        data?.error?.message ?? 'Failed to send email',
                    )

                    return // Stop further iterations if one email fails
                }

                // const finalRes = await response.json()
                const senderObj = userDetials.gmailAccounts?.find(
                    (item: any) => item?.email === from,
                )
                console.log({ userDetials, senderObj })

                // Assuming saveEmail returns a Promise
                const saveEmailPromise = saveEmail({
                    from: from,
                    to: trimmedEmail, // Adjust as needed
                    content: message,
                    subject: subject,
                    senderName: `${senderObj?.firstName ?? ''} ${
                        senderObj?.lastName ?? ''
                    }`,
                    mailType: senderObj?.mailType,
                    avatar: senderObj?.avatar ?? '',
                })

                // Add the promise to the array
                saveEmailPromises.push(saveEmailPromise)
            }

            // Wait for all saveEmail promises to complete
            await Promise.all(saveEmailPromises)

            showToast('success', 'Emails sent successfully')
            // console.log('Emails sent successfully')
        } catch (error) {
            showToast('error', 'Permission denied!')
            setIsLoading(false)
            console.error('Error sending emails:', error)
        }
    }

    const sendEmailUsingGmailAPI = async (
        accessToken: string,
        toEmails: string[],
        subject: string,
        message: string,
        from: string,
    ) => {
        try {
            setIsLoading(true)

            // Array to store promises of saveEmail calls
            const saveEmailPromises = []

            // Iterate over each email address
            for (const toEmail of toEmails) {
                const trimmedEmail = toEmail.trim()
                const email = `From: ${from}\r\nTo: ${trimmedEmail}\r\nSubject: ${
                    subject ?? 'testing'
                }\r\n\r\n${message}`
                // Make a request to the Gmail API to send the email
                const response = await fetch(
                    'https://www.googleapis.com/gmail/v1/users/me/messages/send',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        // Encode the email message in base64
                        body: JSON.stringify({
                            raw: btoa(unescape(encodeURIComponent(email))),
                        }),
                    },
                )

                console.log('accessToken*', accessToken)

                if (!response.ok) {
                    const data = await response.json()
                    console.log({ response }, data)
                    console.error('Failed to send email:', response.statusText)
                    setIsLoading(false)
                    showToast(
                        'error',
                        data?.error?.status ?? 'Failed to send email',
                    )

                    return // Stop further iterations if one email fails
                }
                const finalRes = await response.json()
                const senderObj = userDetials.gmailAccounts?.find(
                    (item: any) => item?.email === from,
                )
                console.log({ finalRes, userDetials, senderObj })

                // Assuming saveEmail returns a Promise
                const saveEmailPromise = saveEmail({
                    from: from,
                    to: trimmedEmail, // Adjust as needed
                    content: message,
                    subject: subject,
                    senderName: `${senderObj?.firstName ?? ''} ${
                        senderObj?.lastName ?? ''
                    }`,
                    emailId: finalRes?.id,
                    threadId: finalRes?.threadId,
                    labelIds: finalRes?.labelIds,
                    mailType: senderObj?.mailType,
                    avatar: senderObj?.avatar ?? '',
                })

                // Add the promise to the array
                saveEmailPromises.push(saveEmailPromise)
            }

            // Wait for all saveEmail promises to complete
            await Promise.all(saveEmailPromises)

            // showToast('success', 'Emails sent successfully')
            console.log('Emails sent successfully')
        } catch (error) {
            showToast('error', 'Permission denied!')
            setIsLoading(false)
            console.error('Error sending emails:', error)
        }
    }

    useEffect(() => {
        if (userDetials?.gmailAccounts?.length > 0) {
            const from =
                userDetials.gmailAccounts?.[
                    userDetials.gmailAccounts?.length - 1
                ]?.accessToken
            console.log('fronmnnn', from, userDetials)
            reset({
                ...getValues(),
                from,
            })
            setMail(
                userDetials?.gmailAccounts?.find(
                    (item: any) => item.accessToken === from,
                )?.mailType as string,
            )
        }
    }, [userDetials])
    useEffect(() => {
        console.log('which mail', mail)
    }, [mail])

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
        ],
    }

    const hasAccessToken = watch('from')
    const isValid = isAccessTokenValid(hasAccessToken)
    console.log(
        'hasAccessToken',
        hasAccessToken,
        'parsedddd',
        parseJwt(hasAccessToken),
    )
    return (
        <CommonModal
            isOpen={isOpen}
            onClose={onClose}
            title="Email"
            size="2xl"
            primaryAction={{
                label: 'send',
                loading: isLoading,
                ...(hasAccessToken && {
                    onClick: () => {
                        mail === 'microsoft'
                            ? sendEmailUsingMicrosoftGraphAPI(
                                  from,
                                  to,
                                  subject,
                                  message,
                                  userDetials?.gmailAccounts?.find(
                                      (item: any) => item.accessToken === from,
                                  )?.email,
                              )
                            : sendEmailUsingGmailAPI(
                                  from,
                                  to,
                                  subject,
                                  message,
                                  userDetials?.gmailAccounts?.find(
                                      (item: any) => item.accessToken === from,
                                  )?.email,
                              )
                    },
                }),
            }}
        >
            <Flex gap={1} alignItems="center" marginBottom="8px">
                <Text fontWeight="bold">To</Text>
                <Box flex={1}>
                    <Controller
                        control={control}
                        name="to"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <ReactMultiEmail
                                    style={{ padding: 0, border: 0 }}
                                    placeholder="Enter the email address"
                                    emails={value ?? []}
                                    onChange={(_emails: string[]) => {
                                        onChange(_emails)
                                    }}
                                    autoFocus={true}
                                    getLabel={(email, index, removeEmail) => {
                                        return (
                                            <div data-tag key={index}>
                                                <div data-tag-item>{email}</div>
                                                <span
                                                    data-tag-handle
                                                    onClick={() =>
                                                        removeEmail(index)
                                                    }
                                                >
                                                    ×
                                                </span>
                                            </div>
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                </Box>
            </Flex>
            <Flex
                gap={2}
                alignItems="center"
                paddingBottom="8px"
                borderBottom="1px solid #dfe3eb"
            >
                <Text fontWeight="bold">From</Text>
                <Flex flex={1} display="flex" alignItems="center">
                    {!userDetials?.email && (
                        <>
                            <Text textTransform="capitalize" mr={'5px'}>
                                {firstName}
                            </Text>
                            <Text textTransform="capitalize" mr="10px">
                                {' '}
                                {lastName}
                            </Text>
                        </>
                    )}

                    <FormControl>
                        <Controller
                            control={control}
                            name="from"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        border={0}
                                        placeholder=""
                                        size="md"
                                        w="100%"
                                        maxW="200px"
                                        _focusVisible={{
                                            border: 0,
                                        }}
                                        color="primary"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        <option
                                            value={
                                                userDetials?.accessToken ?? ''
                                            }
                                            color="red"
                                        >
                                            {userDetials?.email}
                                        </option>
                                        {userDetials?.gmailAccounts?.map(
                                            (item: any) => {
                                                return (
                                                    <option
                                                        value={
                                                            item?.accessToken
                                                        }
                                                    >
                                                        {item?.email}
                                                    </option>
                                                )
                                            },
                                        )}
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>
                </Flex>
            </Flex>

            <Flex
                gap={1}
                alignItems="center"
                marginBottom="8px"
                my={2}
                borderBottom="1px solid #dfe3eb"
            >
                <Text fontWeight="bold">Subject</Text>
                <Box flex={1}>
                    <Controller
                        control={control}
                        name="subject"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    border={0}
                                    _focusVisible={{ border: 0 }}
                                />
                            )
                        }}
                    />
                </Box>
            </Flex>

            {hasAccessToken ? (
                <Controller
                    control={control}
                    name="message"
                    render={({ field: { onChange, value } }) => {
                        return (
                            <ReactQuill
                                style={{ marginTop: '20px' }}
                                placeholder="start typing to enter an email..."
                                id={`emailEditor`}
                                modules={modules}
                                value={value}
                                onChange={value => {
                                    onChange(value)
                                }}
                            />
                        )
                    }}
                />
            ) : (
                <ConnectWithAccounts />
            )}
        </CommonModal>
    )
}

export default EmailModal
