import { useQuery } from '@tanstack/react-query'
import { client } from './apiClient'
import { type EmailingUser } from 'utils/types/emailing.type'

export const useGetEmailList = () =>
    useQuery<EmailingUser[], Error>(
        ['getEmailingList'],
        async () => await client(`propertyOwner/getOwnerEmails`),
    )

export const useGetEmail = (id?: string) =>
    useQuery<EmailingUser, Error>(
        ['getEmailUser', id],
        async () => await client(`propertyOwner/getEmailDetails/${id}`),
        {
            enabled: !!id,
        },
    )
