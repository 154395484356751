import React from 'react'
import { FaDeleteLeft } from 'react-icons/fa6'
import { Box, Text } from '@chakra-ui/react'
import { useDialValue } from './utility'

const NumberDisplay = () => {
    const { dialValue, handleRemoveLastChar } = useDialValue()
    let showBackspace = null

    if (dialValue?.length) {
        showBackspace = (
            <Box
                as="a"
                position="absolute"
                left="100%"
                marginLeft="-5rem"
                cursor="pointer"
                marginTop="20px"
                onClick={e => {
                    e.preventDefault()
                    handleRemoveLastChar()
                }}
            >
                <FaDeleteLeft size={40} />
            </Box>
        )
    }
    return (
        <Box
            background="linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%)"
            lineHeight="5rem"
            textAlign="center"
            fontSize="3.2rem"
            position="relative"
        >
            {showBackspace}
            <Text h="75px" margin="0" fontSize="53px" color="cornsilk">
                {dialValue}
            </Text>
        </Box>
    )
}

export default NumberDisplay
