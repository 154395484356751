import * as process from 'process'
import React, { useState } from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { FaGoogle, FaMicrosoft } from 'react-icons/fa'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import MicrosoftLogin from 'react-microsoft-login'
import CommonModal from 'components/CommonModal'
import { userAtom } from 'store'
import { connectEmailModalAtom } from 'store/modals'
import { useSaveGmailDetails } from 'utils/api/auth.api'
// import { Client } from '@microsoft/microsoft-graph-client'
import { useLoading } from 'App'
import { showToast } from 'utils/helper'
interface EmailModalProps {
    isOpen: boolean
    onClose: () => void
}
const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''
const REGISTERED_REDIRECT_URI =
    process.env.REACT_APP_REGISTERED_REDIRECT_URI || ''
const AUTHORITY_URI = process.env.REACT_APP_AUTHORITY_URI || ''

const ConnectEmailModal: React.FC<EmailModalProps> = ({
    isOpen,
    onClose,
}: EmailModalProps) => {
    const [userDetails, setUserDetails] = useAtom(userAtom)
    const { startLoading, stopLoading } = useLoading()
    const { mutate: saveGmailData } = useSaveGmailDetails({
        onSuccess: () => {
            stopLoading()
        },
        onError: () => {
            stopLoading()
        },
    })

    const [email, setEmail] = useState<any>({
        value: '',
        error: '',
    })
    // console.log({ email })
    const [connectEmailModal, setConnectEmailModal] = useAtom(
        connectEmailModalAtom,
    )

    const fetchGmailUserDetails = (user: any) => {
        try {
            if (user) {
                startLoading()
                axios
                    .get(
                        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                        {
                            headers: {
                                Authorization: `Bearer ${user.access_token}`,
                                Accept: 'application/json',
                            },
                        },
                    )
                    .then((res: any) => {
                        const currentDate = new Date()
                        const expires = new Date(
                            currentDate.getTime() + 60 * 60 * 1000,
                        )
                        console.log('==========resres=========', res, expires)
                        const payload = {
                            firstName: res.data.given_name,
                            lastName: res.data.family_name,
                            accessToken: user.access_token,
                            email: res.data.email,
                            picture: res.data.picture,
                            expires,
                            mailType: 'google',
                        }
                        console.log(
                            'google expires',
                            payload,
                            res.headers.expires,
                        )
                        saveGmailData(payload)
                    })
                    .catch(err => {
                        stopLoading()
                        console.log(err)
                    })
            }
        } catch (error) {
            stopLoading()
        }
    }
    const authenticateUser = useGoogleLogin({
        onSuccess: codeResponse => {
            console.log('codeResponse', codeResponse)
            fetchGmailUserDetails(codeResponse)
            setUserDetails({ ...codeResponse, email: email.value })
            localStorage.setItem('access_token', codeResponse?.access_token)
            setConnectEmailModal({
                status: false,
                data: '',
            })

            // console.log('codeResponse', codeResponse)
        },
        onError: error => console.log('Login Failed:', error),

        scope: 'https://mail.google.com/ https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/admin.directory.resource.calendar',
    })
    const authenticateMicrosoftUser = (err: any, data: any) => {
        startLoading()
        if (err) {
            console.error('Microsoft Login Failed:', err)
            setEmail((prev: any) => ({
                ...prev,
                error: 'invalid gmail account',
            }))
            stopLoading()
        } else {
            const currentDate = new Date()
            const expires = new Date(currentDate.getTime() + 60 * 60 * 1000)
            console.log('Microsoft Authentication Success:', data, expires)
            const payload = {
                firstName: data.givenName,
                lastName: data.surname,
                accessToken: data.accessToken,
                email: data.mail,
                picture: data.picture,
                expires,
                mailType: 'microsoft',
            }
            saveGmailData(payload)
            setUserDetails({ ...data, email: email.value })
            localStorage.setItem('access_token', data?.accessToken)
            setConnectEmailModal({
                status: false,
                data: '',
            })
            stopLoading()
            // Handle Microsoft authentication success
            // You can fetch user details or perform any necessary actions
            // Update the user details state or trigger relevant functions
        }
    }
    // @ts-ignore
    return (
        <CommonModal isOpen={isOpen} onClose={onClose} title="">
            <Flex
                flexDirection="column"
                gap={'20px'}
                justifyContent="center"
                alignItems="center"
            >
                <Text fontSize={22}>Set up your email account</Text>

                <Button
                    leftIcon={<FaGoogle />}
                    onClick={() => authenticateUser()}
                >
                    Sign in with Google
                </Button>
                {MICROSOFT_CLIENT_ID === '' &&
                    showToast('error', 'Microsoft Client Id missing')}
                <MicrosoftLogin
                    clientId={MICROSOFT_CLIENT_ID}
                    authCallback={authenticateMicrosoftUser}
                    redirectUri={REGISTERED_REDIRECT_URI}
                    tenantUrl={AUTHORITY_URI}
                    withUserData={true}
                    buttonTheme={'dark'}
                    graphScopes={['user.read', 'mail.read', 'mail.send']}
                >
                    {/* Empty React fragment */}
                    <Button leftIcon={<FaMicrosoft />}>
                        Sign in with Microsoft
                    </Button>
                </MicrosoftLogin>
            </Flex>
        </CommonModal>
    )
}

export default ConnectEmailModal
