import React, { useEffect, useState } from 'react'
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Select,
    Textarea,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { v4 as uuidv4 } from 'uuid'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import ApiCalendar from 'react-google-calendar-api'
import { ReactMultiEmail } from 'react-multi-email'
import CommonModal from 'components/CommonModal'
import { userAtom } from 'store'
import { useLoadGoogle } from 'utils/hooks/useLoadGoogle'
import { useCreateEvent } from 'utils/api/contacts.api'
import { createMeetingModal } from 'store/modals'
const locales = {
    'en-US': enUS,
}

const now = new Date()

const events = [
    {
        id: 0,
        title: 'All Day Event very long title',
        allDay: true,
        start: new Date(2015, 3, 0),
        end: new Date(2015, 3, 1),
    },
    {
        id: 14,
        title: 'Today',
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
        id: 15,
        title: 'Point in Time Event',
        start: now,
        end: now,
    },
]

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})
const ColoredDateCellWrapper = ({ children }: any) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    })
interface EmailModalProps {
    isOpen: boolean
    onClose: () => void
}
const MeetingModal: React.FC<EmailModalProps> = ({
    isOpen,
    onClose,
}: EmailModalProps) => {
    const [savingEventInGoolge, setSavingEventInGoolge] = useState(false)
    const calendarApi = new ApiCalendar({
        clientId:
            '440381447931-tsevjslcpuu23qo81b8o25fone7r3o21.apps.googleusercontent.com',
        apiKey: '',
        scope: 'https://www.googleapis.com/auth/calendar',
        discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        ],
    })

    const [userDetials] = useAtom(userAtom)
    const [meetingModal] = useAtom(createMeetingModal)
    const eventsList = meetingModal?.data
    console.log({ eventsList })
    const validationSchema = Yup.object().shape({
        startDate: Yup.string().required(),
        endDate: Yup.string().required(),
        title: Yup.string().required(),
        desc: Yup.string().required(),
        host: Yup.string().required(),
    })

    const defaultValues = {
        startDate: undefined,
        endDate: undefined,
        title: '',
        desc: '',
        host: '',
        description: '',
        attendees: [],
    }
    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm<any>({
        defaultValues,
        resolver: yupResolver(validationSchema) as any,
        mode: 'all',
    })

    const { mutate: createEvent, isLoading: creatingEvent } = useCreateEvent({
        onSuccess: () => {
            onClose()
            reset(defaultValues)
            setSavingEventInGoolge(false)
        },
    })

    const handleGoogleApiLoad = () => {
        // Perform your action here
        console.log('Google API loaded!')
    }
    useLoadGoogle(handleGoogleApiLoad)

    useEffect(() => {
        console.log('user deatils**', userDetials)
        if (userDetials?.gmailAccounts?.length > 0) {
            const host = userDetials.gmailAccounts?.[0]?.email

            reset({
                ...getValues(),
                host,
            })
        }
    }, [userDetials])

    const handleAuthClick = async () => {
        const resApi = await calendarApi.handleAuthClick()

        if ((resApi as any)?.access_token) {
            addCalendarEvents()
        }
        console.log({ resApi })
    }

    const addCalendarEvents = () => {
        const { startDate, endDate, title, attendees, description, host } =
            getValues()
        console.log('startDate', startDate, endDate)
        const event = {
            summary: title,
            start: {
                dateTime: startDate,
                timeZone: 'UTC',
            },
            end: {
                dateTime: endDate,
                timeZone: 'UTC',
            },
            conferenceDataVersion: 1,
            conferenceData: {
                createRequest: {
                    requestId: uuidv4(),
                    conferenceSolutionKey: {
                        type: 'hangoutsMeet',
                    },
                },
            },
            attendees: attendees?.map((item: string) => {
                return {
                    email: item,
                }
            }),
            description: description,
        }
        setSavingEventInGoolge(true)
        calendarApi
            .createEvent(event)
            .then((result: any) => {
                console.log('create evebt restult', result)
                const payload = {
                    from: host,
                    to: attendees,
                    title,
                    description,
                    startDate: new Date(startDate).toISOString(),
                    endDate: new Date(endDate).toISOString(),
                    meetingLink: result?.result?.hangoutLink,
                    status: result?.result?.status,
                }
                console.log({ payload })
                setSavingEventInGoolge(false)
                createEvent(payload)
            })
            .catch((error: any) => {
                console.log(error)
            })
    }

    return (
        <CommonModal
            isOpen={isOpen}
            onClose={onClose}
            title="Schedule"
            size="5xl"
        >
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                <GridItem
                    colSpan={2}
                    display="flex"
                    flexDirection="column"
                    gap={4}
                >
                    <FormControl>
                        <FormLabel>Host</FormLabel>
                        <Controller
                            control={control}
                            name="host"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        placeholder=""
                                        size="md"
                                        w="100%"
                                        maxW="sm"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        <option value={userDetials?.email}>
                                            {userDetials?.email}
                                        </option>
                                        {userDetials?.gmailAccounts?.map(
                                            (item: any) => (
                                                <option
                                                    key={item.email}
                                                    value={item?.email}
                                                >
                                                    {item?.email}
                                                </option>
                                            ),
                                        )}
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Attendees</FormLabel>
                        <Controller
                            control={control}
                            name="attendees"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <ReactMultiEmail
                                        style={{ padding: 0 }}
                                        placeholder="Enter the email address"
                                        emails={value ?? []}
                                        onChange={(_emails: string[]) => {
                                            onChange(_emails)
                                        }}
                                        autoFocus={true}
                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail,
                                        ) => {
                                            return (
                                                <div data-tag key={index}>
                                                    <div data-tag-item>
                                                        {email}
                                                    </div>
                                                    <span
                                                        data-tag-handle
                                                        onClick={() =>
                                                            removeEmail(index)
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            }}
                        />
                    </FormControl>

                    <>
                        <FormControl>
                            <FormLabel>Title</FormLabel>
                            <Controller
                                control={control}
                                name="title"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Input
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                '.react-datepicker-wrapper': {
                                    w: '100%',
                                },
                            }}
                            mr="3"
                            isInvalid={!!errors.startDate?.message}
                        >
                            <FormLabel>Start Date</FormLabel>
                            <Controller
                                control={control}
                                name="startDate"
                                rules={{
                                    required: 'Start Date Required',
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy hh:mm:a"
                                            selected={
                                                value
                                                    ? new Date(value)
                                                    : undefined
                                            }
                                            onChange={onChange} //only when value has changed
                                            minDate={new Date()}
                                        />
                                    )
                                }}
                            />
                            <FormErrorMessage>
                                {(errors.startDate?.message as string) ?? ''}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            sx={{
                                '.react-datepicker-wrapper': {
                                    w: '100%',
                                },
                            }}
                            mr="3"
                            isInvalid={!!errors.endDate?.message}
                        >
                            <FormLabel>End Date</FormLabel>
                            <Controller
                                control={control}
                                name="endDate"
                                rules={{
                                    required: 'End Date Required',
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy hh:mm:a"
                                            selected={
                                                value
                                                    ? new Date(value)
                                                    : undefined
                                            }
                                            onChange={onChange} //only when value has changed
                                            minDate={new Date()}
                                        />
                                    )
                                }}
                            />
                            <FormErrorMessage>
                                {(errors.endDate?.message as string) ?? ''}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Controller
                                control={control}
                                name="description"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Textarea
                                            value={value}
                                            onChange={onChange}
                                            minH="150px"
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                    </>
                </GridItem>
                <GridItem colSpan={3}>
                    <Calendar
                        localizer={localizer}
                        events={
                            eventsList &&
                            Array.isArray(eventsList) &&
                            eventsList.length > 0
                                ? eventsList?.map((item: any) => {
                                      return {
                                          id: item?._id,
                                          title: item?.title,

                                          // start: new Date(2015, 3, 0),
                                          // end: new Date(2015, 3, 1),
                                          start: new Date(item.startDate),
                                          end: new Date(item.endDate),
                                      }
                                  })
                                : []
                        }
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: '100%' }}
                        onRangeChange={value => {
                            console.log({ value })
                        }}
                        onSelectSlot={value => {
                            console.log('onSelectSlot', value)
                        }}
                    />
                </GridItem>
            </Grid>

            <Flex mt={4} justifyContent="flex-end" gap={2}>
                <Button variant="outline" onClick={() => onClose}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleAuthClick()
                    }}
                    isLoading={savingEventInGoolge || creatingEvent}
                >
                    Save
                </Button>
            </Flex>
        </CommonModal>
    )
}

export default MeetingModal
