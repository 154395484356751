import { TriangleDownIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'

export const InsightsTabel = () => {
    return (
        <Box>
            <Flex alignItems="center">
                <Text
                    color=" #00557F"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="20px"
                    paddingRight="10px"
                >
                    Activities
                </Text>
                <TriangleDownIcon height="10px" color=" #00557F" />
            </Flex>
            <Flex
                paddingY="20px"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <Text
                        color=" #333"
                        fontSize=" 24px"
                        fontStyle="normal"
                        fontWeight=" 400"
                        lineHeight="normal"
                    >
                        Calls Connected this week
                    </Text>
                    <Text
                        color=" #666"
                        fontSize=" 12px"
                        fontStyle="normal"
                        fontWeight=" 400"
                        lineHeight="normal"
                    >
                        Date range: This week so far | Frequency: Daily |
                        Compared To: Last Week
                    </Text>
                </Box>
                <Box>
                    <Text
                        color=" #00557F"
                        fontSize=" 30px"
                        fontStyle="normal"
                        fontWeight=" 400"
                        lineHeight="normal"
                    >
                        0
                    </Text>
                </Box>
            </Flex>
            <Divider backgroundColor="#ccc" border="1px solid #ccc" />
            <Flex
                paddingY="20px"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <Text
                        color=" #333"
                        fontSize=" 24px"
                        fontStyle="normal"
                        fontWeight=" 400"
                        lineHeight="normal"
                    >
                        Meetings Completed this week
                    </Text>
                    <Text
                        color=" #666"
                        fontSize=" 12px"
                        fontStyle="normal"
                        fontWeight=" 400"
                        lineHeight="normal"
                    >
                        Date range: This week so far | Compared To: Last Week
                    </Text>
                </Box>
                <Box>
                    <Text
                        color=" #00557F"
                        fontSize=" 30px"
                        fontStyle="normal"
                        fontWeight=" 400"
                        lineHeight="normal"
                    >
                        0
                    </Text>
                </Box>
            </Flex>
            <Divider backgroundColor="#ccc" border="1px solid #ccc" />
            <Box paddingY="20px">
                <Text
                    color=" #333"
                    fontSize=" 24px"
                    fontStyle="normal"
                    fontWeight=" 400"
                    lineHeight="normal"
                >
                    Weekly Activity Report
                </Text>
                <Text
                    color=" #666"
                    fontSize=" 12px"
                    fontStyle="normal"
                    fontWeight=" 400"
                    lineHeight="normal"
                >
                    Date range: This week so far | Compared To: Last Week
                </Text>
            </Box>
        </Box>
    )
}
