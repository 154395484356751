import { type UseToastOptions } from '@chakra-ui/react'
import { toast } from 'App'

export function sidebarLoader() {
    const menu = ['stats', 'name']
    return { menu }
}

export const isValidEmail = (email: any) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
}

export const isValidGmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email matches the general email format
    if (!regex.test(email)) {
        return false
    }

    // Check if the email domain is "gmail.com"
    const domain = email.split('@')[1]
    return domain.toLowerCase() === 'gmail.com'
}

export function showToast(
    status: UseToastOptions['status'],
    title?: UseToastOptions['title'],
    description?: UseToastOptions['description'],
) {
    return toast({
        status,
        title,
        description,
        position: 'top-right',
    })
}

export const removeHtmlTags = (quillContent: string) => {
    if (!quillContent) return
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = quillContent
    return tempDiv.textContent || tempDiv.innerText || ''
}

export const isAccessTokenValid = (accessToken: string) => {
    if (!accessToken) {
        // If there is no access token, it's considered invalid
        return false
    }

    // Decode the access token to get its claims, including expiration time
    const decodedToken = parseJwt(accessToken)

    if (!decodedToken || !decodedToken.exp) {
        // If the token cannot be decoded or does not have an expiration time, consider it invalid
        return false
    }

    // Get the current timestamp
    const currentTimestamp = Math.floor(Date.now() / 1000)

    // Check if the token has not expired
    return decodedToken.exp > currentTimestamp
}

// Function to decode a JWT (JSON Web Token)

export const parseJwt = (token: any) => {
    try {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                ?.map(
                    char =>
                        '%' +
                        ('00' + char.charCodeAt(0).toString(16)).slice(-2),
                )
                .join(''),
        )
        console.log({ jsonPayload })

        return JSON.parse(jsonPayload)
    } catch (error) {
        console.error('Error decoding token:', error)
        return null
    }
}
