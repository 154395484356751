export default {
    baseStyle: {
        fontSize: '16px',
    },
    variants: {
        primary: {
            color: 'secondaryText',
        },
        secondary: {
            color: 'primary.500',
        },
        white: {
            color: 'white',
        },
    },
}
