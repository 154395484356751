import React from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'
import EmailCard from '../EmailCard'
import { useGetContact } from '../../../../../../../utils/api/contacts.api'
import useOutlookHookLogic from './useOutlookHookLogic'

const InboxTab = () => {
    const filteredInbox = useOutlookHookLogic('Inbox')
    const { id } = useParams()
    const { data } = useGetContact(id)
    const filteredItems = filteredInbox.filter(
        item => item.from[0] === data?.email,
    )
    return (
        <>
            {filteredItems.length > 0 ? (
                filteredInbox.map((item, index) => (
                    <EmailCard key={index} item={item} index={index} />
                ))
            ) : (
                <Spinner size="xl" color="blue.500" />
            )}
        </>
    )
}

export default InboxTab
