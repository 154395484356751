import React from 'react'
import { Outlet, NavLink, useLocation } from 'react-router-dom'
import {
    Box,
    Flex,
    Image,
    Button,
    Avatar,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    HStack,
} from '@chakra-ui/react'
import { useSetAtom } from 'jotai'
import { TriangleDownIcon } from '@chakra-ui/icons'
import { SIDEBAR_MENU } from 'utils/constants'
import { useGetProfile } from 'utils/api/auth.api'
import { userAtom } from 'store'
export default function Dashboard() {
    const location = useLocation()
    console.log({ location })
    const pathname = location.pathname.split('/')?.[2]
    console.log({ pathname }, location.pathname.split('/'))
    const setAtom = useSetAtom(userAtom)
    const { data: profile } = useGetProfile({
        onSuccess: (res: any) => {
            console.log({ res })
            setAtom(res)
        },
    })
    console.log('userdetailss', { profile })
    function camelCaseToNormalCase(camelCaseString: string) {
        if (!camelCaseString) return
        // Split the string at each capital letter
        const words = camelCaseString?.split(/(?=[A-Z])/)

        // Capitalize the first letter of each word and join them with a space
        const normalCaseString = words
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')

        return normalCaseString
    }

    const renderLabel = ({ label }: any) => {
        if (
            pathname === 'contacts' ||
            pathname === 'emailing' ||
            pathname === 'connectAccounts'
        ) {
            return label
        } else {
            return camelCaseToNormalCase(pathname)
        }
    }

    return (
        <>
            <Box as="header" bg="primary.500">
                <Flex
                    as="nav"
                    p="13px 30px"
                    alignItems="center"
                    justify="space-between"
                >
                    <Image
                        maxW="100px"
                        src="https://tknismtest.s3.amazonaws.com/global_dev/tech-dev-1696828474843.png"
                        alt="logo"
                    />
                    <Flex justify="center">
                        {SIDEBAR_MENU?.map(({ label, path, childpaths }) => {
                            console.log({ label, path, childpaths })
                            if (path) {
                                return (
                                    <Button
                                        key={label}
                                        as={NavLink}
                                        to={path}
                                        // rightIcon={<TriangleDownIcon />}
                                        w="100%"
                                    >
                                        {label}
                                    </Button>
                                )
                            } else {
                                return (
                                    <HStack>
                                        <Menu>
                                            <MenuButton
                                                w="100%"
                                                as={Button}
                                                rightIcon={
                                                    <TriangleDownIcon height="12px" />
                                                }
                                            >
                                                {renderLabel({ label })}
                                            </MenuButton>
                                            <MenuList>
                                                {childpaths?.map(
                                                    (item: any) => {
                                                        return (
                                                            <MenuItem
                                                                as={NavLink}
                                                                to={item.path}
                                                                key={item.path}
                                                                bg={
                                                                    item?.path ===
                                                                    pathname
                                                                        ? 'gray.200'
                                                                        : 'inherit'
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    },
                                                )}
                                            </MenuList>
                                        </Menu>
                                    </HStack>
                                )
                            }
                        })}
                    </Flex>
                    <Avatar rounded="10px" />
                </Flex>
            </Box>
            <Box as="main" p="30px" flex="1">
                <Outlet />
            </Box>
        </>
    )
}
