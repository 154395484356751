import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { client } from './apiClient'
import { web3 } from 'utils/web3'
import { showToast } from 'utils/helper'
import {
    type Profile,
    type AuthRes,
    type LoginPayload,
    type LoginRes,
    type Web3EthSign,
} from 'utils/types/auth.type'

export const useLoginRequest = () => {
    const navigate = useNavigate()
    return useMutation<LoginRes, Error, LoginPayload>(
        async ({ address }) => {
            return await client(`user/getUser/${address}`)
        },
        {
            onError(error) {
                showToast('error', error?.message)
            },
            onSuccess(data, variables) {
                handleSignMessage({
                    type: 'login',
                    publicAddress: variables?.address,
                    nonce: data?.nonce,
                    navigate,
                })
            },
        },
    )
}

function handleSignMessage(data: Web3EthSign) {
    web3.eth.personal
        .sign(
            web3.utils.utf8ToHex(`Login to AKRU - Nonce: ${data.nonce}`),
            data?.publicAddress,
            data?.nonce?.toString(),
        )
        .then(async signature => {
            await handleLoginAuth(signature, data)
        })
        .catch(e => {
            showToast('error', e.message)
        })
}

const handleLoginAuth = async (
    signature: string,
    { publicAddress, type, navigate }: Web3EthSign,
) => {
    await client(`user/auth`, {
        data: {
            publicAddress,
            signature,
            type,
        },
    })
        .then((response: AuthRes) => {
            console.log('=========response========', response)
            const decoded: any = jwt_decode(response?.body?.token as any)
            console.log('=========decoded========', decoded)
            if (decoded?.role === 'propertyOwner') {
                response.body.role = decoded.role
                localStorage.setItem('id', response.body?.id)
                localStorage.setItem('role', response.body?.role)
                localStorage.setItem('token', response.body?.token)
                showToast('success', 'Successfully logged In!')
                setTimeout(() => {
                    window.location.href = '/dashboard/contacts'
                }, 1000)
            } else if (decoded?.role === 'ownerRequest') {
                showToast(
                    'error',
                    'Your request is in pending for admin approval',
                )
                navigate('/')
            } else {
                showToast('error', 'Invalid Login')
            }
        })
        .catch(error => {
            showToast('error', error.response.body.data.message)
            setTimeout(() => {
                window.location.href = '/login'
            }, 1500)
        })
}

export const useGetProfile = ({ onSuccess }: any) =>
    useQuery<Profile, Error>(
        ['getOwnerProfile'],
        async () => await client(`propertyOwner/getprofile`),
        {
            onSuccess: data => {
                onSuccess?.(data)
            },
        },
    )

export const useSaveGmailDetails = ({
    onSuccess,
    onError,
}: {
    onSuccess?: any
    onError?: any
} = {}) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async (data: any) => {
            return await client(`propertyOwner/saveGmailDetails`, {
                data: data,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getOwnerProfile'] })

            onSuccess?.()
        },
        onError: err => {
            onError?.()
        },
    })

    return mutation
}
