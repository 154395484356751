import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { client } from './apiClient'
import { type ContactUser } from 'utils/types/contacts.type'

type props = {
    onSuccess?: any
}
export const useGetContactList = ({ onSuccess }: props = {}) =>
    useQuery<ContactUser[], Error>(
        ['getContactList'],
        async () => await client(`propertyOwner/getOwnersLeadContacts`),
        {
            onSuccess: res => {
                onSuccess?.(res)
            },
        },
    )

export const useGetCrmAccounts = ({ onSuccess }: props = {}) =>
    useQuery<ContactUser[], Error>(
        ['getCrmAccounts'],
        async () => await client(`crm/getSubAccounts`),
        {
            onSuccess: res => {
                onSuccess?.(res)
            },
        },
    )

export const useGetContact = (id?: string) =>
    useQuery<ContactUser, Error>(
        ['getContact', id],
        async () => await client(`propertyOwner/leadContactsDetail/${id}`),
        {
            enabled: !!id,
        },
    )

export const useUploadFile = () =>
    useMutation<any, Error, any>(async data => {
        const formData = new FormData()
        formData.append('file', data?.file)
        return await client(
            `media-upload/mediaFiles/${data?.folderName}/${data?.field}`,
            {
                data: formData,
            },
        )
    })

export const useCreateNotes = ({
    onSuccess,
    onError,
}: {
    onSuccess?: any
    onError?: any
} = {}) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async (data: any) => {
            return await client(`crm/addNotes`, {
                data: data,
            })
        },
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: ['get-notes'] })

            onSuccess?.(data)
        },
        onError: err => {
            onError?.()
        },
    })

    return mutation
}

export const useGetNotes = ({
    onSuccess,

    onError,
}: {
    onSuccess?: any

    onError?: any
} = {}) => {
    return useQuery(['get-notes'], async () => await client(`crm/getNotes`), {
        keepPreviousData: true,

        onSuccess: () => {
            onSuccess?.()
        },
        onError: () => {
            onError?.()
        },
    })
}

export const useCreateEmail = ({
    onSuccess,
    onError,
}: {
    onSuccess?: any
    onError?: any
} = {}) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async (data: any) => {
            return await client(`crm/saveEmail`, {
                data: data,
            })
        },
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: ['get-emails'] })

            onSuccess?.(data)
        },
        onError: err => {
            onError?.()
        },
    })

    return mutation
}

const fetchThreadData = async (threadId: string, accessToken: string) => {
    const response = await fetch(
        `https://www.googleapis.com/gmail/v1/users/me/threads/${threadId}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    )
    return await response.json()
}

export const useThreadData = (matchedDataArray: any[]) => {
    const threadIdsArray = matchedDataArray?.map(item => item.threadId)

    return useQuery(
        ['threadData', threadIdsArray], // unique query key
        async () => {
            const promises = matchedDataArray?.map(item =>
                fetchThreadData(item.threadId, item.accessToken),
            )
            return Promise.all(promises)
        },
        {
            enabled: !!threadIdsArray.length, // only fetch when there are threadIds
        },
    )
}
export const useInbox = (tokens: string[], label: string) => {
    try {
        const inboxPromises = tokens.map(async token => {
            const mailFoldersResponse = await fetch(
                'https://graph.microsoft.com/v1.0/me/mailFolders',
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )

            const mailFoldersData = await mailFoldersResponse.json()
            const inboxId = getEmailFolderId(mailFoldersData.value, label)

            if (inboxId) {
                const emailsResponse = await fetch(
                    `https://graph.microsoft.com/v1.0/me/mailFolders/${inboxId}/messages`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                )

                return await emailsResponse.json()
            }
        })

        // Use Promise.all to wait for all promises to resolve
        return useQuery(['emails', label], async () => {
            console.log('Executing useQuery callback')
            const results = await Promise.all(inboxPromises)
            console.log('Results:', label, results)
            return results
        })
    } catch (e) {
        console.error('Error fetching inbox data:', e)
        return []
    }
    // Create an array of Promises for fetching inbox data with each token
}

const getEmailFolderId = (folders: any[] | undefined, folderName: string) => {
    console.log('folderName', folderName)
    const folder = folders?.find(folder => folder.displayName === folderName)
    return folder ? folder.id : null
}

export const useGetEmail = ({
    onSuccess,

    onError,
}: {
    onSuccess?: any

    onError?: any
} = {}) => {
    return useQuery(['get-emails'], async () => await client(`crm/getEmails`), {
        keepPreviousData: true,

        onSuccess: () => {
            onSuccess?.()
        },
        onError: () => {
            onError?.()
        },
    })
}

export const useGetActivities = ({
    onSuccess,

    onError,
}: {
    onSuccess?: any

    onError?: any
} = {}) => {
    return useQuery(
        ['get-activities'],
        async () => await client(`crm/getActivities`),
        {
            keepPreviousData: true,

            onSuccess: () => {
                onSuccess?.()
            },
            onError: () => {
                onError?.()
            },
        },
    )
}

export const useCreateTask = ({
    onSuccess,
    onError,
}: {
    onSuccess?: any
    onError?: any
} = {}) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async (data: any) => {
            return await client(`crm/addTask`, {
                data: data,
            })
        },
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: ['get-tasks'] })

            onSuccess?.(data)
        },
        onError: err => {
            onError?.()
        },
    })

    return mutation
}

export const useGetTasks = ({
    onSuccess,

    onError,
}: {
    onSuccess?: any

    onError?: any
} = {}) => {
    return useQuery(['get-tasks'], async () => await client(`crm/getTasks`), {
        keepPreviousData: true,

        onSuccess: () => {
            onSuccess?.()
        },
        onError: () => {
            onError?.()
        },
    })
}

export const useGetEvents = ({
    onSuccess,

    onError,
}: {
    onSuccess?: any

    onError?: any
} = {}) => {
    return useQuery(
        ['get-meetings'],
        async () => await client(`crm/getAllMeeting`),
        {
            keepPreviousData: true,

            onSuccess: () => {
                onSuccess?.()
            },
            onError: () => {
                onError?.()
            },
        },
    )
}
export const useCreateEvent = ({
    onSuccess,
    onError,
}: {
    onSuccess?: any
    onError?: any
} = {}) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async (data: any) => {
            return await client(`crm/saveMeetingInfo`, {
                data: data,
            })
        },
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: ['get-meetings'] })

            onSuccess?.(data)
        },
        onError: err => {
            onError?.()
        },
    })

    return mutation
}
