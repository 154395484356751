import React, { useState } from 'react'
import { Button, ButtonGroup, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import DataTable from 'components/table'
import { emailModalAtom } from 'store/modals'
import { useGetEmail } from 'utils/api/contacts.api'
export default function SentBox() {
    const [searchText, setSearchText] = useState('')
    const [emailModal, setEmailModal] = useAtom(emailModalAtom)
    const { data: emailList } = useGetEmail()
    console.log('emailList', emailList)
    // const { data } = useGetEmailList()

    const renderTable = (type: string) => {
        return (
            <DataTable
                isHeader
                columns={columns}
                data={emailList || []}
                emptyData={
                    <Text
                        display="block"
                        m="20px 0 0"
                        textAlign="center"
                        fontSize="26px"
                        as="strong"
                    >
                        No Data Found
                    </Text>
                }
            />
        )
    }

    return <>{renderTable('sent')}</>
}

const columnHelper = createColumnHelper<any>()
const columns = [
    columnHelper.accessor('senderName', {
        header: 'Name',
    }),
    columnHelper.accessor('to', {
        header: 'To',
    }),
    columnHelper.accessor('createdAt', {
        cell: info => `${new Date(info.getValue()).toLocaleDateString()}`,
        header: 'Date',
    }),
    columnHelper.accessor('_id', {
        header: '',
        cell: info => (
            <ButtonGroup w="max-content">
                <Button
                    as={Link}
                    to={`/dashboard/email/${info?.getValue()}`}
                    w="120px"
                >
                    View
                </Button>
                <Button w="120px">Delete</Button>
            </ButtonGroup>
        ),
    }),
]
