import React from 'react'
import { useAtom } from 'jotai/index'
import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Heading,
    SimpleGrid,
    Spinner,
} from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { userAtom } from '../../store'
import ConnectWithAccounts from './components/ConnectWithAccounts'

const cardStyles = {
    borderWidth: '1px',
    borderRadius: 'lg',
    overflow: 'hidden',
}
const ConnectAccounts = () => {
    const [userDetials] = useAtom(userAtom)
    const isLoading = !userDetials?.gmailAccounts

    return (
        <>
            {isLoading ? (
                // Show loading state
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Spinner />
                </Box>
            ) : (
                <Box>
                    <SimpleGrid
                        spacing={4}
                        templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                        padding="40px"
                    >
                        {userDetials.gmailAccounts.map(account => (
                            <Card key={account.id} {...cardStyles} width="auto">
                                <CardHeader>
                                    <Heading whiteSpace="nowrap" size="md">
                                        {account.email}
                                    </Heading>
                                </CardHeader>
                                <CardBody>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Text fontWeight="bold">
                                            Full Name:
                                        </Text>
                                        <Text>{`${account.firstName} ${account.lastName}`}</Text>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Text fontWeight="bold">
                                            Mail Type:
                                        </Text>
                                        <Text color="dodgerblue">
                                            {account.mailType}
                                        </Text>
                                    </Box>
                                </CardBody>
                            </Card>
                        ))}
                    </SimpleGrid>
                </Box>
            )}
            <ConnectWithAccounts />
        </>
    )
}

export default ConnectAccounts
