import { Magic } from 'magic-sdk'
import Web3 from 'web3'

const customNodeOptions = {
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
    chainId: 1, // corresponding chainId for your rpc url
}

export const megic = new Magic(process.env.REACT_APP_MAGIC_KEY ?? 'API_KEY', {
    network: customNodeOptions, // connected to Polygon Mainnet!
})

export const web3 = new Web3(megic.rpcProvider as any)
// export const accounts = megic.wallet.connectWithUI()
