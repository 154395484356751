import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import ConnectEmailModal from './ConnectEmailModal'
import useQueryCacheValue from 'utils/hooks/useQueryCacheValue'
import { connectEmailModalAtom } from 'store/modals'

const ConnectWithAccounts = () => {
    const [connectEmailModal, setConnectEmailModal] = useAtom(
        connectEmailModalAtom,
    )
    const { id } = useParams()

    const contactDetail = useQueryCacheValue(['getContact', id])
    const { email } = contactDetail ?? {}

    return (
        <>
            <Flex
                justifyContent="center"
                alignItems="center"
                minH="150px"
                padding="40px"
            >
                <Box textAlign="center">
                    <Text fontSize={20} fontWeight="semibold" mb={4}>
                        Connect with your email
                    </Text>
                    <Text mb={4}>
                        The selected from address isn't connected to IMP-CRM.
                        You must connect {email} before you can use it to send
                        emails from your CRM.
                    </Text>
                    <Button
                        onClick={() => {
                            setConnectEmailModal({
                                status: true,
                                data: '',
                            })
                        }}
                    >
                        Connect Inbox
                    </Button>
                </Box>
            </Flex>
            <ConnectEmailModal
                isOpen={connectEmailModal.status}
                onClose={() => {
                    setConnectEmailModal({
                        status: false,
                        data: '',
                    })
                }}
            />
        </>
    )
}

export default ConnectWithAccounts
