import React, { useEffect, useState } from 'react'
import { Spinner } from '@chakra-ui/react'
import { useAtom } from 'jotai/index'
import { useParams } from 'react-router-dom'
import EmailCard from '../EmailCard'
// import { showToast } from '../../../../../../../utils/helper'
import { userAtom } from '../../../../../../../store'
import { useGetContact } from '../../../../../../../utils/api/contacts.api'
import useOutlookHookLogic from './useOutlookHookLogic'

const ArchiveTab = () => {
    const filteredInbox = useOutlookHookLogic('Archive')
    const [userDetails] = useAtom(userAtom)
    const [checkMicrosoftLogged, setCheckMicrosoftLogged] = useState(false)
    const { id } = useParams()
    const { data } = useGetContact(id)
    useEffect(() => {
        const tempCheck = userDetails?.gmailAccounts?.some(
            item => item.mailType === 'microsoft',
        )
        if (tempCheck === false) {
            // showToast('info', 'Kindly connect your Microsoft Account.')
        }
        console.log('tempCheck', tempCheck)
    }, [userDetails])
    const filteredItems = filteredInbox.filter(
        item => item.from === data?.email || item.to === data?.email,
    )

    return (
        <>
            {filteredItems.length > 0 ? (
                filteredItems.map((item, index) => (
                    <EmailCard key={index} item={item} index={index} />
                ))
            ) : (
                <Spinner size="xl" color="blue.500" />
            )}
        </>
    )
}

export default ArchiveTab
