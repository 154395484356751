import { Box, Button, Flex, TabPanel } from '@chakra-ui/react'
import React from 'react'
import { useAtom } from 'jotai'
import { showToast } from '../../../../../../utils/helper'
import MeetingModal from './components/MeetingModal'
import MeetingCard from './components/MeetingCard'
import { createMeetingModal } from 'store/modals'
import { useGetEvents } from 'utils/api/contacts.api'
import { userAtom } from 'store'
// import { google } from 'googleapis'
// interface GoogleAuthOptions {
//     accessToken: string
// }
const MeetingTab = () => {
    const [meetingModal, setMeetingModal] = useAtom(createMeetingModal)
    const { data: calenderEvents } = useGetEvents()
    const [userDetials] = useAtom(userAtom)
    console.log({ calenderEvents })
    return (
        <TabPanel>
            <Flex justifyContent="flex-end">
                <Button
                    onClick={() => {
                        userDetials?.gmailAccounts?.length === 0
                            ? showToast(
                                  'info',
                                  'Kindly connect gmail or outlook Accounts',
                              )
                            : setMeetingModal({
                                  status: true,
                                  data: calenderEvents,
                              })
                    }}
                >
                    Create Meeting
                </Button>
            </Flex>
            <Box>
                {calenderEvents?.map((item: any, index: number) => {
                    return <MeetingCard item={item} index={index} />
                })}
            </Box>

            <MeetingModal
                isOpen={meetingModal?.status}
                onClose={() => {
                    setMeetingModal({
                        status: false,
                        data: '',
                    })
                }}
            />
        </TabPanel>
    )
}

export default MeetingTab
