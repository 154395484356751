import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import ActivityTab from './components/ActivityTab'

import EmailTab from './components/EmailTab'
import MeetingTab from './components/MeetingTab'
import NotesTab from './components/NotesTab'
import CallsTab from './components/Calls'
import TaskTab from './components/TasksTab'

const Activity = () => {
    return (
        <TabPanel>
            <Tabs variant="primary">
                <TabList>
                    <Tab>Activity</Tab>
                    <Tab>Notes</Tab>
                    <Tab>Email</Tab>
                    <Tab>Calls</Tab>
                    <Tab>Tasks</Tab>
                    <Tab>Meetings</Tab>
                </TabList>
                <TabPanels>
                    <ActivityTab />
                    <TabPanel>
                        <NotesTab />
                    </TabPanel>
                    <TabPanel>
                        <EmailTab />
                    </TabPanel>
                    <TabPanel>
                        <CallsTab />
                    </TabPanel>
                    <TabPanel>
                        <TaskTab />
                    </TabPanel>
                    <MeetingTab />
                </TabPanels>
            </Tabs>
        </TabPanel>
    )
}

export default Activity
