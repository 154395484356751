export default {
    global: {
        body: {
            fontFamily: `'Pragati Narrow', sans-serif`,
            overflowX: 'hidden',
            bg: 'background',
            color: 'text',
            fontSize: '16px',
        },
    },
}
