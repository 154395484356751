import React from 'react'
import { Navigate } from 'react-router-dom'

export default function PrivateRoute({
    isAuthenticated,
    children,
}: {
    isAuthenticated: boolean
    children: JSX.Element
}) {
    if (isAuthenticated) return children
    else return <Navigate to="/login" />
}
