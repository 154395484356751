import { Card, CardBody, Flex, Heading, TabPanel, Text } from '@chakra-ui/react'
import { useGetActivities } from 'utils/api/contacts.api'
import { formatDate } from 'utils/formatDateTime'

const ActivityTab = () => {
    const { data: activitiesList } = useGetActivities()
    console.log({ activitiesList })
    return (
        <TabPanel>
            {activitiesList?.map((item: any) => {
                return (
                    <Card key={item?._id} rounded="10px" mb={3}>
                        <CardBody>
                            <Flex justify="space-between" mb="15px">
                                <Heading
                                    as="h6"
                                    variant="primary"
                                    fontSize="16px"
                                >
                                    {item?.type}
                                </Heading>
                                <Text
                                    as="span"
                                    fontSize="14px"
                                    variant="primary"
                                >
                                    {formatDate(new Date(item?.createdAt))}
                                </Text>
                            </Flex>
                            <Text as="span" variant="primary">
                                {item?.activity}
                            </Text>
                        </CardBody>
                    </Card>
                )
            })}
        </TabPanel>
    )
}

export default ActivityTab
