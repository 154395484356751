import { Search2Icon, TriangleDownIcon } from '@chakra-ui/icons'
import {
    Box,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react'
import React from 'react'

export const AllTabel = () => {
    return (
        <Box height="750px">
            <Flex alignItems="center">
                <InputGroup w="40%">
                    <Input
                        borderRadius="10px"
                        backgroundColor="#fff"
                        border="1px solid #ccc"
                        placeholder="Serach"
                        height="46px"
                    />
                    <InputRightElement>
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
                <Flex
                    justify="space-between"
                    alignItems="center"
                    paddingLeft="10px"
                >
                    <HStack spacing="20px">
                        <Flex
                            height="fit-content"
                            width="140px"
                            borderRadius="10px"
                            border="1px solid #ccc"
                            backgroundColor="rgba(0, 85, 127, 0.10)"
                            paddingX="20px"
                            paddingY="10px"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Menu>
                                <HStack
                                    width="inherit"
                                    justifyContent="space-between"
                                >
                                    <MenuButton
                                        color="#666"
                                        fontSize="16px"
                                        fontWeight="normal"
                                    >
                                        <Text as="span">(1)</Text> Activity
                                    </MenuButton>
                                    <MenuButton>
                                        {' '}
                                        <TriangleDownIcon height="10px" />
                                    </MenuButton>
                                </HStack>
                                <MenuList>
                                    <MenuItem>New File</MenuItem>
                                    <MenuItem>New Window</MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                        <HStack
                            height="fit-content"
                            width="140px"
                            borderRadius="10px"
                            border="1px solid #ccc"
                            backgroundColor="rgba(0, 85, 127, 0.10)"
                            paddingX="20px"
                            paddingY="10px"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text
                                color="#666"
                                fontSize="16px"
                                fontStyle=" normal"
                                fontWeight=" 400"
                                lineHeight="normal"
                            >
                                More Filters
                            </Text>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                            >
                                <path
                                    d="M0.771432 3.08573C0.345385 3.08573 0 3.43111 0 3.85716C0 4.2832 0.345385 4.62859 0.771432 4.62859V3.08573ZM0.771432 13.3715C0.345385 13.3715 0 13.7169 0 14.1429C0 14.5689 0.345385 14.9143 0.771432 14.9143V13.3715ZM6.94289 14.9143C7.36893 14.9143 7.71432 14.5689 7.71432 14.1429C7.71432 13.7169 7.36893 13.3715 6.94289 13.3715V14.9143ZM19.2858 14.9143C19.7118 14.9143 20.0572 14.5689 20.0572 14.1429C20.0572 13.7169 19.7118 13.3715 19.2858 13.3715V14.9143ZM13.1143 3.08573C12.6883 3.08573 12.3429 3.43111 12.3429 3.85716C12.3429 4.2832 12.6883 4.62859 13.1143 4.62859V3.08573ZM19.2858 4.62859C19.7118 4.62859 20.0572 4.2832 20.0572 3.85716C20.0572 3.43111 19.7118 3.08573 19.2858 3.08573V4.62859ZM0.771432 4.62859H3.85716V3.08573H0.771432V4.62859ZM0.771432 14.9143H6.94289V13.3715H0.771432V14.9143ZM16.2001 14.9143H19.2858V13.3715H16.2001V14.9143ZM13.1143 4.62859H19.2858V3.08573H13.1143V4.62859ZM15.4286 14.1429C15.4286 15.421 14.3924 16.4572 13.1143 16.4572V18.0001C15.2446 18.0001 16.9715 16.2732 16.9715 14.1429H15.4286ZM13.1143 16.4572C11.8362 16.4572 10.8 15.421 10.8 14.1429H9.25718C9.25718 16.2732 10.9841 18.0001 13.1143 18.0001V16.4572ZM10.8 14.1429C10.8 12.8648 11.8362 11.8286 13.1143 11.8286V10.2858C10.9841 10.2858 9.25718 12.0126 9.25718 14.1429H10.8ZM13.1143 11.8286C14.3924 11.8286 15.4286 12.8648 15.4286 14.1429H16.9715C16.9715 12.0126 15.2446 10.2858 13.1143 10.2858V11.8286ZM9.25718 3.85716C9.25718 5.13531 8.22099 6.17145 6.94289 6.17145V7.71432C9.07317 7.71432 10.8 5.98741 10.8 3.85716H9.25718ZM6.94289 6.17145C5.66474 6.17145 4.62859 5.13531 4.62859 3.85716H3.08573C3.08573 5.98741 4.81263 7.71432 6.94289 7.71432V6.17145ZM4.62859 3.85716C4.62859 2.57901 5.66474 1.54286 6.94289 1.54286V0C4.81263 0 3.08573 1.72691 3.08573 3.85716H4.62859ZM6.94289 1.54286C8.22099 1.54286 9.25718 2.57901 9.25718 3.85716H10.8C10.8 1.72691 9.07317 0 6.94289 0V1.54286Z"
                                    fill="#999999"
                                />
                            </svg>
                        </HStack>
                    </HStack>
                </Flex>
            </Flex>
            <Flex height="inherit" justifyContent="center" alignItems="center">
                <Text
                    color=" #00557F"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="20px"
                >
                    You’re all caught up on tasks.
                </Text>
            </Flex>
        </Box>
    )
}
