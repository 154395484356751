// utility.js
import { atom, useAtom } from 'jotai/index'

export const dialValueAtom = atom('')
export const filteredContactsAtom = atom(get => contacts => {
    const dialpadValue = get(dialValueAtom)

    if (!dialpadValue) {
        return contacts
    }

    return contacts.filter(contact =>
        contact.phone.toString().includes(dialpadValue),
    )
})

export const useFilteredContacts = data => {
    const [getFilteredContacts] = useAtom(filteredContactsAtom)

    // Note: Call the function returned by useAtom to get the filtered contacts
    return getFilteredContacts(data)
}

export const useDialValue = () => {
    const [dialValue, setDialValue] = useAtom(dialValueAtom)

    const handleAppendDialValue = value => {
        setDialValue(prevValue => prevValue + value)
    }
    const phoneFormat = phoneNumber => {
        return (phoneNumber + '').replace(
            /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
            '$1-$2-$3',
        )
    }

    const handleRemoveLastChar = () => {
        setDialValue(prevValue => prevValue.slice(0, prevValue.length - 1))
    }

    return {
        dialValue,
        handleAppendDialValue,
        handleRemoveLastChar,
        phoneFormat,
    }
}

export const dialConfig = [
    ['1', ''],
    ['2', 'ABC'],
    ['3', 'DEF'],
    ['4', 'GHI'],
    ['5', 'JKL'],
    ['6', 'MNO'],
    ['7', 'PQRS'],
    ['8', 'TUV'],
    ['9', 'WXYZ'],
    ['*', ''],
    ['0', '#'],
    ['+', ''],
]
