import React, { useState } from 'react'
import {
    Box,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { SearchIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import { type ContactUser } from 'utils/types/contacts.type'
import DataTable from 'components/table'
import { useGetContactList } from 'utils/api/contacts.api'

export default function Contacts() {
    const { data, isLoading } = useGetContactList()
    const [searchText, setSearchText] = useState('')
    return (
        <>
            <Flex justify="space-between" mb="35px">
                <Box>
                    <Heading as="h1">Contacts </Heading>
                    <Text as="span" fontSize="24px">
                        {data?.length} Records
                    </Text>
                </Box>
                <Flex>
                    <InputGroup>
                        <Input
                            variant="primary"
                            onChange={e => {
                                setSearchText(e.target.value)
                            }}
                            placeholder="Search"
                        />
                        <InputRightElement>
                            <SearchIcon />
                        </InputRightElement>
                    </InputGroup>
                </Flex>
            </Flex>
            <DataTable
                columns={columns}
                data={
                    data?.filter(
                        item =>
                            item?.email
                                .toLowerCase()
                                .includes(searchText?.toLowerCase()),
                    ) ?? []
                }
                emptyData={
                    isLoading ? (
                        <Spinner />
                    ) : (
                        <Text
                            display="block"
                            m="20px 0 0"
                            textAlign="center"
                            fontSize="26px"
                            as="strong"
                        >
                            No Data Found
                        </Text>
                    )
                }
            />
        </>
    )
}

const columnHelper = createColumnHelper<ContactUser>()
const columns = [
    columnHelper.accessor('firstName', {
        header: 'Full Name',
        cell: info => (
            <Link
                to={`/dashboard/contact/${info?.row?.original?._id}`}
                style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textTransform: 'uppercase',
                }}
            >
                {info?.row?.original?.firstName} {info?.row?.original?.lastName}
            </Link>
        ),
    }),
    columnHelper.accessor('email', {
        header: 'Email',
    }),
    columnHelper.accessor('phone', {
        header: 'Phone',
    }),
    columnHelper.accessor('leadStatus', {
        header: 'Lead Status',
        cell: info => info.getValue() ?? '-',
    }),
    columnHelper.accessor('contactOwner', {
        header: 'Contact owner',
    }),
    columnHelper.accessor('createdAt', {
        cell: info => `${new Date(info.getValue()).toLocaleDateString()}`,
        header: 'Created At',
    }),
    columnHelper.accessor('companyName', {
        header: 'Company name',
    }),
    columnHelper.accessor('city', {
        header: 'City',
    }),
    columnHelper.accessor('state', {
        header: 'State/Region',
        cell: info => info.getValue()?.name ?? '-',
    }),
]
