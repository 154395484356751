import {
    Avatar,
    Box,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
} from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import Overview from './components/Overview'
import Activity from './components/Activity'
import { useGetContact } from 'utils/api/contacts.api'

export default function ContactDetail() {
    const { id } = useParams()
    const { data } = useGetContact(id)

    return (
        <Flex gap="20px">
            <Card maxW="400px" w="100%" minH="calc(100vh - 140px)">
                <CardHeader as={Flex} gap="20px" borderBottom="1px solid #CCC">
                    <Avatar minH="100px" minW="100px" rounded="20px" />
                    <Box>
                        <Heading as="h6" fontSize="16px">
                            {data?.firstName} {data?.lastName}
                        </Heading>
                        <Text as="span" variant="primary" fontSize="14px">
                            {data?.email}
                        </Text>
                        <Flex mt="10px" gap="10px">
                            <Box textAlign="center">
                                <Avatar rounded="10px" size="sm" />
                                <Text
                                    variant="primary"
                                    display="block"
                                    as="span"
                                    fontSize="10px"
                                >
                                    Note
                                </Text>
                            </Box>
                            <Box textAlign="center">
                                <Avatar rounded="10px" size="sm" />
                                <Text
                                    variant="primary"
                                    display="block"
                                    as="span"
                                    fontSize="10px"
                                >
                                    Email
                                </Text>
                            </Box>
                            <Box textAlign="center">
                                <Avatar rounded="10px" size="sm" />
                                <Text
                                    variant="primary"
                                    display="block"
                                    as="span"
                                    fontSize="10px"
                                >
                                    Call
                                </Text>
                            </Box>
                            <Box textAlign="center">
                                <Avatar rounded="10px" size="sm" />
                                <Text
                                    variant="primary"
                                    display="block"
                                    as="span"
                                    fontSize="10px"
                                >
                                    Task
                                </Text>
                            </Box>
                            <Box textAlign="center">
                                <Avatar rounded="10px" size="sm" />
                                <Text
                                    variant="primary"
                                    display="block"
                                    as="span"
                                    fontSize="10px"
                                >
                                    Meeting
                                </Text>
                            </Box>
                        </Flex>
                    </Box>
                </CardHeader>
                <CardBody p="20px">
                    <Heading as="h6" fontSize="16px">
                        About this contact
                    </Heading>
                    <Heading as="h6" fontSize="16px" mt="20px">
                        Email
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        {data?.email}
                    </Text>
                    <Heading as="h6" fontSize="16px" mt="20px">
                        Phone number
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        {data?.phone}
                    </Text>
                    <Heading as="h6" fontSize="16px" mt="20px">
                        Contact Owner
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        {data?.contactOwner}
                    </Text>
                    <Heading as="h6" fontSize="16px" mt="20px">
                        Last Contacted
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        September 3rd, 2023{' '}
                    </Text>
                    <Heading as="h6" fontSize="16px" mt="20px">
                        Lifecycle Stage
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        {data?.leadStatus}
                    </Text>
                </CardBody>
            </Card>
            <Card flex="1">
                <Tabs variant="primary">
                    <TabList>
                        <Tab>Overview</Tab>
                        <Tab>Activity</Tab>
                    </TabList>
                    <TabPanels>
                        <Overview data={data} />
                        <Activity />
                    </TabPanels>
                </Tabs>
            </Card>
        </Flex>
    )
}
