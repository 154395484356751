import Button from './button'
import Text from './text'
import Input from './input'
import Card from './card'
import Heading from './heading'
import Tabs from './tabs'

export default {
    Button,
    Text,
    Input,
    Card,
    Heading,
    Tabs,
}
