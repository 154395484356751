import { useEffect, useState } from 'react'
import { useAtom } from 'jotai/index'
import DOMPurify from 'dompurify'
import { userAtom } from '../../../../../../../store'
import { useInbox } from '../../../../../../../utils/api/contacts.api'

const useOutlookHookLogic = label => {
    console.log('label=>', label)
    const [userDetails] = useAtom(userAtom)
    const [tempInboxToken, setTempInboxToken] = useState([])
    const [filteredInbox, setFilteredInbox] = useState([])

    useEffect(() => {
        if (userDetails && Array.isArray(userDetails?.gmailAccounts)) {
            const inboxTokens = userDetails?.gmailAccounts
                .filter(account => account.mailType === 'microsoft')
                .map(user => user.accessToken)

            setTempInboxToken(inboxTokens)
        }
    }, [userDetails])

    const {
        data: inboxData,
        isLoading: inboxLoading,
        isError: inboxError,
    } = useInbox(tempInboxToken, label)
    console.log('inboxLoading=====', label, inboxLoading)
    useEffect(() => {
        const tempInbox = []
        inboxData?.map(inbox => {
            inbox?.value?.map(message => {
                const emailObject = {
                    subject: message.subject,
                    from: message.from?.emailAddress?.address || '',
                    createdAt: message.createdDateTime,
                    senderName: message.sender?.emailAddress?.name || '',
                    content: DOMPurify.sanitize(message.body?.content || ''),
                    to:
                        message.toRecipients?.map(
                            recipient => recipient.emailAddress?.address,
                        ) || [],
                }
                tempInbox.push(emailObject)
            })
        })

        setFilteredInbox(tempInbox)
    }, [inboxData, userDetails])
    return filteredInbox
}

export default useOutlookHookLogic
