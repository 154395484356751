import React, { useEffect } from 'react'
import { useAtom } from 'jotai/index'
import { useParams } from 'react-router-dom'
import EmailCard from '../EmailCard'
// import { showToast } from '../../../../../../../utils/helper'
import { userAtom } from '../../../../../../../store'
import { useGetContact } from '../../../../../../../utils/api/contacts.api'
import useGmailHookLogic from './useGmailHookLogic'

const InboxTab = () => {
    const filteredInbox = useGmailHookLogic('INBOX')
    const [userDetails] = useAtom(userAtom)
    const { id } = useParams()
    const { data } = useGetContact(id)
    console.log('data-email++', data?.email, filteredInbox)

    useEffect(() => {
        const hasGoogleAccount = userDetails?.gmailAccounts?.some(
            item => item.mailType === 'google',
        )

        if (hasGoogleAccount === false) {
            // showToast('info', 'Kindly connect your Google Account.')
        }
    }, [userDetails])
    const extractEmailAddress = fromField => {
        const emailRegex = /<([^>]+)>/
        const match = fromField.match(emailRegex)
        if (match && match[1]) {
            return match[1]
        }
        return fromField
    }
    const filteredItems = filteredInbox.filter(
        item => extractEmailAddress(item.from) === data?.email,
    )

    if (filteredItems?.length === 0) {
        return <div>No records found</div>
    }

    return filteredItems.map((item, index) => (
        <EmailCard key={index} item={item} index={index} />
    ))
}

export default InboxTab
