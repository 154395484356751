import { Box, Button, Flex } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React from 'react'
import CreateNoteModal from './components/CreateNoteModal'
import NotesCard from './components/NotesCard'
import { createNoteModalAtom } from 'store/modals'
import { useGetNotes } from 'utils/api/contacts.api'

const NotesTab = () => {
    const [notesModal, setNotesModal] = useAtom(createNoteModalAtom)

    const { data: notesList, isLoading } = useGetNotes()

    return (
        <Box>
            <Flex justifyContent="end">
                <Button
                    onClick={() => {
                        setNotesModal({
                            status: true,
                            data: '',
                        })
                    }}
                >
                    Create Note
                </Button>
            </Flex>

            {notesList?.map((item: any) => {
                return <NotesCard item={item} />
            })}

            <CreateNoteModal
                isOpen={notesModal.status}
                onClose={() => {
                    setNotesModal({
                        status: false,
                        data: '',
                    })
                }}
            />
        </Box>
    )
}

export default NotesTab
