import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from 'utils/formatDateTime'
const MeetingCard = ({ item, index }: any) => {
    return (
        <>
            <Box
                key={index}
                backgroundColor="#fdfdfd"
                marginTop="20px"
                border="1px solid #ccc"
                padding="10px"
                borderRadius="5px"
                boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Box>
                            <Text paddingRight="5px">
                                Meeting - <span>{item?.title}</span>
                            </Text>
                        </Box>
                        <Text mx={1}>By</Text>
                        <Box>
                            <Text fontSize="16px" textColor="#666">
                                {item.from}
                            </Text>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box paddingLeft="5px">
                            <Text fontSize="16px" textColor="#666">
                                {formatDate(new Date(item.createdAt))}
                            </Text>
                        </Box>
                    </Flex>
                </Flex>
                <Text color="gray">with {item?.to?.[0]}</Text>
                <Flex alignItems="center">
                    <Text
                        width="8px"
                        height="8px"
                        rounded="full"
                        backgroundColor="green"
                    ></Text>
                    <Text fontSize="13px" textColor="#666" paddingLeft="6px">
                        SENT
                    </Text>
                </Flex>

                <Box marginTop="10px" paddingX="18px">
                    <Text fontSize="16px" textColor="#666">
                        {item.description}
                    </Text>
                    <Link style={{ color: 'blue' }} to={item?.meetingLink}>
                        {item?.meetingLink}{' '}
                    </Link>
                </Box>
            </Box>
        </>
    )
}
export default MeetingCard
