import React from 'react'
import { useParams } from 'react-router-dom'
import {
    useGetContact,
    useGetEmail,
} from '../../../../../../../utils/api/contacts.api'
import EmailCard from '../EmailCard'

const SentTab = () => {
    const { data: emailList } = useGetEmail()
    const { id } = useParams()
    const { data } = useGetContact(id)
    console.log('emailList', emailList)

    const filteredEmailList = emailList?.filter(
        item => item.mailType === 'microsoft' && item.to === data?.email,
    )
    if (filteredEmailList?.length === 0) {
        return <div>No records found</div>
    }

    return filteredEmailList?.map((item, index) => (
        <EmailCard key={index} item={item} index={index} />
    ))
}

export default SentTab
