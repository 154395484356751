import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Web3 from 'web3'
import { RPCError } from 'magic-sdk'
import { showToast } from 'utils/helper'
import { megic } from 'utils/web3'
import { useLoginRequest } from 'utils/api/auth.api'
import { Logo } from 'assets/icons'
import { useLoading } from 'App'

export default function Login() {
    const { mutate: loginRequest, isLoading } = useLoginRequest()
    const [loader, setLoader] = useState(false)
    const { register, handleSubmit } = useForm<{
        email: string
    }>()
    const { startLoading, stopLoading } = useLoading()
    const onSubmit = handleSubmit(async data => {
        if (!data?.email) return showToast('error', 'please enter some email')
        if (!validateEmail(data?.email)) {
            return showToast('error', 'email is invalid')
        }
        startLoading()
        console.log({
            status: true,
            loadingMessage: 'Fetching Wallet Details.',
        })
        setLoader(true)
        if (await megic.user.isLoggedIn()) await megic.user.logout()
        console.log('state.email', data?.email)

        await megic.auth
            .loginWithMagicLink({ email: data?.email })
            .on('email-sent', () => {
                console.log('email-sent')
            })
            .then(async () => {
                await handleLogin('formatic', data?.email)
            })
            .once('email-not-deliverable', () => {
                console.log({ status: false, loadingMessage: '' })
                setLoader(false)
                showToast('error', `Email Is Not Correct.`)
            })
            .catch(error => {
                stopLoading()
                console.log({ status: false, loadingMessage: '' })
                setLoader(false)
                showToast('error', error)
                if (error instanceof RPCError) {
                    console.log('RPCError', RPCError)
                    // Sentry.captureMessage(RPCError.data + ' ' + ' magicError')
                    console.log({
                        // message: `This user with email ${data?.email} recieved this error : ${error?.data} on performing magicError blockchain transaction`,
                    })
                }
            })
            .on('error', error => {
                setLoader(false)
                stopLoading()
                console.log({ status: false, loadingMessage: '' })
                showToast('error', error)
                // Sentry.captureMessage(error.message + ' magicError')
                console.log({
                    // message: `This user with email ${data?.email} recieved this error : ${error.message} on performing magicError blockchain transaction`,
                })
            })
    })

    const validateEmail = (email: any) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )
    }
    const handleLogin = async (e: string, email: string) => {
        try {
            const web3Injected = new Web3(megic.rpcProvider as any)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const address = (await web3Injected?.currentProvider?.enable())?.[0]
            console.log('address', address)

            const netId = 1
            console.log('netId------------id---------', netId)

            if (netId === 1) {
                console.log({
                    address,
                    email,
                })
                loginRequest({
                    address,
                    email,
                })
                setLoader(false)
                stopLoading()
                console.log({
                    status: true,
                    loadingMessage: 'Signing You in...',
                })
                localStorage.setItem('ETH-PROVIDER', e)
                localStorage.setItem('ETH-ID', address)
            } else {
                console.log({ status: false, loadingMessage: '' })
                stopLoading()
                showToast(
                    'info',
                    `Please switch to ${process.env.REACT_APP_NETWORK} network before login`,
                )
            }
        } catch (e) {
            stopLoading()
            setLoader(false)
            console.log('login error', e)
            // Sentry.captureMessage('login error', e)
            console.log({ status: false, loadingMessage: '' })
            if (e === 'User rejected provider access')
                alert('Login Request Rejected')
            else
                showToast(
                    'error',
                    `Something went wrong. Please reload and try again!`,
                )
            if (e instanceof RPCError) {
                // console.log('RPCError', e?.data)
                // Sentry.captureMessage('magicError', +' ' + RPCError.data)
            }
        }
    }

    return (
        <Flex>
            <Box
                flexDirection="column"
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="50%"
                minH="100vh"
                bg="primary.500"
            >
                <Logo />
                <Text fontSize="5xl" color="white">
                    CRM
                </Text>
            </Box>
            <Center w="50%">
                <Box maxW="400px" w="100%">
                    <Heading as="h1" variant="primary">
                        Login
                    </Heading>
                    <Box mt="40px" as="form" onSubmit={onSubmit}>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input
                                variant="primary"
                                {...register('email')}
                                type="text"
                            />
                        </FormControl>
                        <Button
                            w="100%"
                            mt="25px"
                            type="submit"
                            isLoading={loader || isLoading}
                        >
                            Log in
                        </Button>
                    </Box>
                </Box>
            </Center>
        </Flex>
    )
}
