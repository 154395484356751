import React from 'react'
import ReactQuill from 'react-quill'
import { Box, Flex, FormControl, Input, Select, Text } from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import 'react-quill/dist/quill.snow.css'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateTask, useGetCrmAccounts } from 'utils/api/contacts.api'
import CommonModal from 'components/CommonModal'
import { showToast } from 'utils/helper'

interface TaskModalProps {
    isOpen: boolean
    onClose: () => void
}

const CreateTaskModal: React.FC<TaskModalProps> = ({
    isOpen,
    onClose,
}: TaskModalProps) => {
    const { mutate, isLoading } = useCreateTask({
        onSuccess: () => {
            reset()
            onClose()
            showToast('success', 'task created successfully!')
        },
        onError: () => {},
    })

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
        ],
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
    })
    const defaultValues = {
        title: '',
        type: 'call',
        priority: 'high',
        assignTo: '',
        description: '',
        dueDate: new Date(),
    }

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm<any>({
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: 'all',
    })

    const sendTask = async () => {
        const payload = {
            ...getValues(),
        }
        mutate(payload)
    }

    const { data: contactList, isLoading: contactListLoading } =
        useGetCrmAccounts({
            onSuccess: (data: any) => {
                reset({
                    ...getValues(),
                    assignTo: data?.[0]?._id,
                })
            },
        })
    return (
        <CommonModal
            isOpen={isOpen}
            onClose={onClose}
            title="Task"
            size="2xl"
            primaryAction={{
                label: 'Save Task',
                loading: isLoading,
                onClick: () => {
                    sendTask()
                },
            }}
        >
            <FormControl>
                <Controller
                    control={control}
                    name="title"
                    render={({ field: { onChange, value } }) => {
                        return (
                            <Input
                                value={value}
                                onChange={onChange}
                                placeholder="Enter your task title"
                                style={{ border: '1px solid transparent' }}
                            />
                        )
                    }}
                />
            </FormControl>
            <Flex gap={'20px'} mt={2}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        To-do
                    </Text>

                    <FormControl>
                        <Controller
                            control={control}
                            name="type"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        border={0}
                                        placeholder=""
                                        w="80px"
                                        maxW="200px"
                                        _focusVisible={{
                                            border: 0,
                                        }}
                                        color="#00557f"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        <option value={'todo'}>todo</option>
                                        <option value={'call'}>call</option>
                                        <option value={'email'}>email</option>
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        Priority
                    </Text>

                    <FormControl>
                        <Controller
                            control={control}
                            name="priority"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        border={0}
                                        placeholder=""
                                        w="80px"
                                        maxW="200px"
                                        _focusVisible={{
                                            border: 0,
                                        }}
                                        color="#00557f"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        <option value={'low'}>low</option>
                                        <option value={'medium'}>medium</option>
                                        <option value={'high'}>high</option>
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        Assign To
                    </Text>

                    <FormControl>
                        <Controller
                            control={control}
                            name="assignTo"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        border={0}
                                        placeholder=""
                                        w="170px"
                                        maxW="200px"
                                        _focusVisible={{
                                            border: 0,
                                        }}
                                        color="#00557f"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {contactList?.map(item => {
                                            return (
                                                <option
                                                    key={item?._id}
                                                    value={item?._id}
                                                >
                                                    {item.firstName}-
                                                    {item?.email}
                                                </option>
                                            )
                                        })}
                                    </Select>
                                )
                            }}
                        />
                    </FormControl>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0}
                    alignItems="center"
                >
                    <Text lineHeight={1} fontSize={14}>
                        Due Date
                    </Text>

                    <FormControl>
                        <Controller
                            control={control}
                            name="dueDate"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <DatePicker
                                        selected={value}
                                        onChange={onChange}
                                        dateFormat="MMMM d, yyyy"
                                        className="chakra-input css-1c6x5dy"
                                    />
                                )
                            }}
                        />
                    </FormControl>
                </Box>
            </Flex>

            <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value } }) => {
                    return (
                        <ReactQuill
                            placeholder="notes.."
                            className="quill-notes"
                            id={`tabdetail`}
                            modules={modules}
                            value={value}
                            onChange={value => {
                                onChange(value)
                            }}
                        />
                    )
                }}
            />
        </CommonModal>
    )
}

export default CreateTaskModal
