import {
    Box,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import CompleteActivitiesChart from 'components/charts/completeActivitiesChart'

export const TotalsTable = () => {
    const tempData = [
        {
            name: 'user 1',
            value: 100,
        },
        {
            name: 'user 2',
            value: 200,
        },
        {
            name: 'user 3',
            value: 300,
        },
        {
            name: 'user 4',
            value: 400,
        },
    ]
    return (
        <Box>
            <Box paddingY="30px">
                <Text
                    color="#666"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                >
                    <Text
                        as="span"
                        color="#00557F"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="normal"
                    >
                        113
                    </Text>{' '}
                    Activities completed
                </Text>
                <Flex alignItems="center">
                    <TriangleUpIcon height="10px" color="#36B277" />
                    <Text
                        paddingLeft="10px"
                        color="#666"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="normal"
                    >
                        71.21% increase in activities compared to previous
                        period (July 1, 2023 - July 31, 2023)
                    </Text>
                </Flex>
                <Text
                    color="#666"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                >
                    Date range by activity date:
                    <Text
                        paddingLeft="5px"
                        as="span"
                        color="#00557F"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="normal"
                    >
                        Last month
                    </Text>
                </Text>
            </Box>
            <Box borderRadius="20px" border=" 1px solid #CCC" background="#FFF">
                <Box padding="20px">
                    <Flex paddingY="10px" justifyContent="space-between">
                        <Box>
                            <Text
                                color="#666"
                                fontSize="20px"
                                fontStyle="normal"
                                fontWeight="400"
                                lineHeight="normal"
                            >
                                Date range:
                                <Text
                                    paddingLeft="5px"
                                    as="span"
                                    color="#00557F"
                                    fontSize="20px"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    lineHeight="normal"
                                >
                                    Last month
                                </Text>
                            </Text>
                            <HStack spacing="30px">
                                <Flex alignItems="center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                    >
                                        <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#BDABE9"
                                        />
                                    </svg>
                                    <Text
                                        paddingLeft="5px"
                                        color="#666"
                                        fontSize="20px"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        lineHeight="normal"
                                    >
                                        Email sent to contact
                                    </Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                    >
                                        <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#F2A490"
                                        />
                                    </svg>
                                    <Text
                                        paddingLeft="5px"
                                        color="#666"
                                        fontSize="20px"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        lineHeight="normal"
                                    >
                                        Calls
                                    </Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                    >
                                        <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#61D3D8"
                                        />
                                    </svg>
                                    <Text
                                        paddingLeft="5px"
                                        color="#666"
                                        fontSize="20px"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        lineHeight="normal"
                                    >
                                        Conversation Session
                                    </Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                    >
                                        <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#F4C793"
                                        />
                                    </svg>
                                    <Text
                                        paddingLeft="5px"
                                        color="#666"
                                        fontSize="20px"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        lineHeight="normal"
                                    >
                                        Task
                                    </Text>
                                </Flex>
                            </HStack>
                        </Box>
                        <Flex
                            height="fit-content"
                            width="200px"
                            borderRadius="10px"
                            border="1px solid #ccc"
                            backgroundColor="rgba(0, 85, 127, 0.10)"
                            paddingX="20px"
                            paddingY="10px"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Menu>
                                <HStack
                                    width="inherit"
                                    justifyContent="space-between"
                                >
                                    <MenuButton
                                        color="#666"
                                        fontSize="16px"
                                        fontWeight="normal"
                                    >
                                        Activity Assigneed to
                                    </MenuButton>
                                    <MenuButton>
                                        {' '}
                                        <TriangleDownIcon height="10px" />
                                    </MenuButton>
                                </HStack>
                                <MenuList>
                                    <MenuItem>New File</MenuItem>
                                    <MenuItem>New Window</MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                    </Flex>
                    <CompleteActivitiesChart data={tempData} />
                </Box>
            </Box>
        </Box>
    )
}
