/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
    Box,
    Flex,
    IconButton,
    Button,
} from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    type ColumnDef,
    type SortingState,
    getSortedRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table'
import { LeftLongArrow, RightLongArrow } from 'assets/icons'

export interface DataTableProps<Data extends object> {
    isHeader?: boolean
    data: Data[]
    columns: Array<ColumnDef<Data, any>>
    emptyData?: JSX.Element
}

export default function DataTable<Data extends object>({
    data,
    columns,
    emptyData,
    isHeader,
}: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            sorting,
        },
    })

    return (
        <Box
            rounded={isHeader ?? false ? '0 0 20px 20px' : '20px'}
            border="1px solid #CCC"
            background="white"
            boxShadow={
                isHeader ?? false
                    ? 'none'
                    : '5px 0px 20px 0px rgba(187, 187, 187, 0.20)'
            }
            overflow="hidden"
        >
            <Box p="20px" overflow="auto">
                <Table sx={{ borderCollapse: 'initial', borderSpacing: '0' }}>
                    <Thead position="sticky" top="0" zIndex={1} rounded="10px">
                        {table
                            .getHeaderGroups()
                            ?.map(
                                (headerGroup: {
                                    id: React.Key | null | undefined
                                    headers: any[]
                                }) => (
                                    <Tr
                                        key={headerGroup.id}
                                        sx={{
                                            '> :last-child': {
                                                rounded: '0 10px 10px 0',
                                                borderRight: '1px solid',
                                                borderColor: 'border',
                                            },
                                            '> :first-child': {
                                                rounded: '10px 0 0 10px',
                                                borderLeft: '1px solid',
                                                borderColor: 'border',
                                            },
                                        }}
                                    >
                                        {headerGroup.headers?.map(header => {
                                            const meta: any =
                                                header.column.columnDef.meta
                                            return (
                                                <Th
                                                    fontSize="16px"
                                                    fontFamily={`'Pragati Narrow', sans-serif`}
                                                    color="text"
                                                    py="10px"
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    isNumeric={meta?.isNumeric}
                                                    textTransform="capitalize"
                                                    filter="auto"
                                                    bg="rgba(5, 36, 96, 0.05)"
                                                    borderTop="1px solid"
                                                    borderBottom="1px solid"
                                                    borderColor="border"
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}

                                                    <chakra.span pl="4">
                                                        {
                                                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                                                            header.column.getIsSorted() ? (
                                                                header.column.getIsSorted() ===
                                                                'desc' ? (
                                                                    <TriangleDownIcon aria-label="sorted descending" />
                                                                ) : (
                                                                    <TriangleUpIcon aria-label="sorted ascending" />
                                                                )
                                                            ) : null
                                                        }
                                                    </chakra.span>
                                                </Th>
                                            )
                                        })}
                                    </Tr>
                                ),
                            )}
                    </Thead>
                    <Tbody>
                        {
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            table.getRowModel().rows?.length ? (
                                table
                                    .getRowModel()
                                    .rows?.map(
                                        (row: {
                                            id: React.Key | null | undefined
                                            getVisibleCells: () => any[]
                                        }) => (
                                            <Tr
                                                key={row.id}
                                                css={`
                                                    :last-child td {
                                                        border: none;
                                                    }
                                                `}
                                            >
                                                {row.getVisibleCells()?.map(
                                                    (cell: {
                                                        column: {
                                                            columnDef: {
                                                                meta: any
                                                                cell: any
                                                            }
                                                        }
                                                        id:
                                                            | React.Key
                                                            | null
                                                            | undefined
                                                        getContext: () => any
                                                    }) => {
                                                        const meta: any =
                                                            cell.column
                                                                .columnDef.meta
                                                        return (
                                                            <Td
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                style={{
                                                                    textAlign:
                                                                        'start',
                                                                }}
                                                                key={cell.id}
                                                                isNumeric={
                                                                    meta?.isNumeric
                                                                }
                                                                position="relative"
                                                                borderColor="lightGray"
                                                            >
                                                                {flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext(),
                                                                )}
                                                            </Td>
                                                        )
                                                    },
                                                )}
                                            </Tr>
                                        ),
                                    )
                            ) : (
                                <Tr
                                    css={`
                                        :last-child td {
                                            border: none;
                                        }
                                    `}
                                >
                                    <Td
                                        colSpan={
                                            table.getHeaderGroups()?.[0]
                                                ?.headers?.length
                                        }
                                    >
                                        {emptyData}
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
            <Flex
                justify="center"
                gap="25px"
                // border-radius: 0px 0px 20px 20px;
                borderTop="1px solid #CCC"
                background="rgba(5, 36, 96, 0.05)"
                py="20px"
            >
                <IconButton
                    aria-label=""
                    icon={<LeftLongArrow />}
                    rounded="50%"
                    bg="#0524600D"
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    color={!table.getCanPreviousPage() ? 'text' : 'primary.500'}
                    _hover={{}}
                    onClick={() => {
                        table.setPageIndex(0)
                    }}
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    isDisabled={!table.getCanPreviousPage()}
                />
                <Button
                    variant="unstyled"
                    onClick={() => {
                        table.previousPage()
                    }}
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    isDisabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Flex align="center">
                    {Array(table.getPageCount())
                        .fill('')
                        ?.map((item, i) => (
                            <Box
                                cursor="pointer"
                                as="span"
                                color={
                                    table.getState().pagination.pageIndex === i
                                        ? 'primary.500'
                                        : 'text'
                                }
                                fontWeight={
                                    table.getState().pagination.pageIndex === i
                                        ? '800'
                                        : '400'
                                }
                                key={i}
                                onClick={() => {
                                    table.setPageIndex(i)
                                }}
                                px="5px"
                            >
                                {i + 1}
                            </Box>
                        ))}
                </Flex>
                <Button
                    variant="unstyled"
                    onClick={() => {
                        table.nextPage()
                    }}
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    isDisabled={!table.getCanNextPage()}
                >
                    Next
                </Button>
                <IconButton
                    aria-label=""
                    icon={<RightLongArrow />}
                    rounded="50%"
                    bg="#0524600D"
                    _hover={{}}
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    color={!table.getCanNextPage() ? 'text' : 'primary.500'}
                    onClick={() => {
                        table.setPageIndex(table.getPageCount() - 1)
                    }}
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    isDisabled={!table.getCanNextPage()}
                />
            </Flex>
        </Box>
    )
}
