import { useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai/index'
import {
    useGetEmail,
    useThreadData,
} from '../../../../../../../utils/api/contacts.api'
import { userAtom } from '../../../../../../../store'

const useGmailHookLogic = label => {
    const { data: emailList } = useGetEmail()
    const [userDetials] = useAtom(userAtom)
    const [inbox, setInbox] = useState([])
    const [filteredInbox, setFilteredInbox] = useState([])
    const matchedDataArray = []

    if (userDetials && Array.isArray(userDetials?.gmailAccounts)) {
        userDetials?.gmailAccounts?.forEach(user => {
            const matchedEmails = emailList?.filter(
                email =>
                    email.mailType === 'google' &&
                    email.from === user.email &&
                    email.threadId.length > 0,
            )

            matchedEmails?.forEach(matchedEmail => {
                const threadObject = {
                    accessToken: user.accessToken,
                    threadId: matchedEmail.threadId,
                }

                matchedDataArray.push(threadObject)
            })
        })
    }
    console.log('threadObjectsArray', matchedDataArray)
    const {
        data: allThreadData,
        isLoading,
        isError,
    } = useThreadData(matchedDataArray)
    const threadsToAdd = useMemo(() => {
        const newThreadsToAdd = []

        if (allThreadData) {
            allThreadData?.forEach(thread => {
                const threadMessage = thread?.messages
                threadMessage?.forEach(message => {
                    let isInInbox = false
                    if (label === 'INBOX') {
                        isInInbox = message?.labelIds?.includes(label)
                        if (isInInbox) {
                            newThreadsToAdd.push(message)
                        }
                    } else {
                        const isInInbox = message?.labelIds?.includes('INBOX')
                        const isInSent = message?.labelIds?.includes('SENT')

                        if (!isInInbox && !isInSent) {
                            newThreadsToAdd.push(message)
                            console.log('message=', message)
                        }
                    }
                })
            })
        }

        return newThreadsToAdd
    }, [allThreadData])

    useEffect(() => {
        if (threadsToAdd.length > 0) {
            console.log('threadsToAdd', threadsToAdd)
            setInbox(threadsToAdd)
            // threadsToAdd.length = 0
        }
    }, [threadsToAdd])

    useEffect(() => {
        console.log('inbox:', inbox)
        const tempInbox = []
        inbox?.forEach(item => {
            const emailObject = {
                subject:
                    item.payload.headers.find(
                        header => header.name === 'Subject',
                    )?.value || '',
                from:
                    item.payload.headers.find(
                        header => header.name === 'Return-Path',
                    )?.value || '',
                createdAt:
                    new Date(
                        item.payload.headers.find(
                            header => header.name === 'Date',
                        )?.value,
                    ).toString() || '',
                senderName:
                    item.payload.headers.find(header => header.name === 'From')
                        ?.value || '',
                content:
                    item.snippet
                        ?.substring(0, item.snippet.indexOf('&lt'))
                        .trim() || item.snippet,
                to:
                    item.payload.headers.find(header => header.name === 'To')
                        ?.value || '',
            }
            console.log('emailObject', emailObject)
            tempInbox.push(emailObject)
            // setFilteredInbox(prevFilteredInbox => [
            //     ...prevFilteredInbox,
            //     emailObject,
            // ])
        })
        setFilteredInbox(tempInbox)
    }, [inbox])
    useEffect(() => {
        console.log('filteredInbox', filteredInbox)
    }, [filteredInbox])
    return filteredInbox
}

export default useGmailHookLogic
