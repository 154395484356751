import {
    Box,
    Button,
    Card,
    CardHeader,
    Center,
    Flex,
    Heading,
    TabPanel,
    Text,
} from '@chakra-ui/react'
import { type ContactUser } from 'utils/types/contacts.type'

const Overview = ({ data }: { data?: ContactUser }) => {
    return (
        <TabPanel>
            <Flex>
                <Box flex="0.3333%">
                    <Heading as="h6" fontSize="16px">
                        Create Date
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        {new Date(data?.createdAt ?? '')?.toUTCString()}
                    </Text>
                </Box>
                <Box flex="0.3333%">
                    <Heading as="h6" fontSize="16px">
                        Lifecycle Stage
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        {data?.leadStatus}
                    </Text>
                </Box>
                <Box flex="0.3333%">
                    <Heading as="h6" fontSize="16px">
                        Last Activity Date
                    </Heading>
                    <Text as="span" variant="primary" fontSize="14px">
                        08/07/2023 4:20 PM GMT+5
                    </Text>
                </Box>
            </Flex>
            <Card mt="40px" rounded="10px">
                <CardHeader>
                    <Heading as="h5" fontSize="16px">
                        Recent Communications
                    </Heading>
                    <Center minH="150px">
                        There are no recent communications
                    </Center>
                </CardHeader>
            </Card>
            <Card mt="20px" rounded="10px">
                <CardHeader>
                    <Flex justify="space-between">
                        <Heading as="h5" fontSize="16px">
                            Companies
                        </Heading>
                        <Button size="sm" minW="90px" rounded="5px">
                            Add
                        </Button>
                    </Flex>
                    <Center minH="150px">
                        No Associated objects of this type exist.
                    </Center>
                </CardHeader>
            </Card>
        </TabPanel>
    )
}

export default Overview
