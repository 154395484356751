import React from 'react'
import { useParams } from 'react-router-dom'
import EmailCard from '../EmailCard'
import { useGetContact } from '../../../../../../../utils/api/contacts.api'
import useGmailHookLogic from './useGmailHookLogic'

const ArchiveTab = () => {
    const filteredInbox = useGmailHookLogic('archive')
    const { id } = useParams()
    const { data } = useGetContact(id)
    const extractEmailAddress = fromField => {
        const emailRegex = /<([^>]+)>/
        const match = fromField.match(emailRegex)
        if (match && match[1]) {
            return match[1]
        }
        return fromField
    }
    const filteredItems = filteredInbox.filter(
        item =>
            extractEmailAddress(item.from) === data?.email ||
            item.to === data?.email,
    )
    if (filteredItems?.length === 0) {
        return <div>No records found</div>
    }

    return filteredItems.map((item, index) => (
        <EmailCard key={index} item={item} index={index} />
    ))
}

export default ArchiveTab
