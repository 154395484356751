import { useQuery } from '@tanstack/react-query'
import { client } from './apiClient'
type props = {
    onSuccess?: any
}
export const useGetDealsInfo = ({ onSuccess }: props = {}) =>
    useQuery(['getDealsInfo'], async () => await client(`crm/getDealInfo`), {
        onSuccess: res => {
            onSuccess?.(res)
        },
    })

export const useGetMyDealsInfo = ({ onSuccess }: props = {}) =>
    useQuery(
        ['getMyDealsInfo'],
        async () => await client(`crm/getOwnDealInfo`),
        {
            onSuccess: res => {
                onSuccess?.(res)
            },
        },
    )
