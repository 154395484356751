export default {
    baseStyle: {
        fontFamily: `'Pragati Narrow', sans-serif`,
        fontWeight: '700',
        fontSize: '24px',
        color: 'text',
    },
    varints: {
        primary: {
            color: 'grayText',
        },
    },
}
