export default {
    primary: {
        50: '#ACC7D5',
        100: '#97B9CA',
        200: '#7DA8BD',
        300: '#5C92AD',
        400: '#337799',
        500: '#00557F',
        600: '#004466',
        700: '#003652',
        800: '#002B42',
        900: '#002235',
    },
    text: '#666666',
    secondaryText: '#999999',
    grayText: '#333333',
    lightGray: '#EEEEEE',
    background: '#FDFDFD',
    border: '#CCCCCC',
    success: '#59C778',
}
