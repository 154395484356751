/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { QueryCache } from '@tanstack/react-query'
import { showToast } from 'utils/helper'
// import * as auth from 'auth-provider'

// const apiURL = 'https://impstagingapi.akru.co/api'
const apiURL = 'https://impdevapi.akru.co/api'
// const apiURL = 'http://192.168.100.50:4001/api'

const queryCache = new QueryCache({
    onError: error => {
        showToast('error', error as any)
    },
    onSuccess: data => {
        showToast('success', data as any)
    },
})

async function client(
    endpoint: string,
    { data, headers: customHeaders, ...customConfig }: any = {},
    fillUrl?: string,
) {
    const isFormData = data instanceof FormData
    const token = localStorage.getItem('token')

    const config: Partial<RequestInit> = {
        method: data ? 'POST' : 'GET',
        body: data ? (isFormData ? data : JSON.stringify(data)) : undefined,
        headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...(data && !isFormData
                ? {
                      'Content-Type': 'application/json',
                  }
                : {}),
            ...customHeaders,
        },
        ...customConfig,
    }
    try {
        return await window
            .fetch(fillUrl ? fillUrl : `${apiURL}/${endpoint}`, config)
            .then(async response => {
                if (fillUrl === 'https://documentanalyzer.akru.co/') {
                    showToast('success', 'Files are uploaded successfully.')
                    return await Promise.resolve()
                }
                // if (response.status === 504) {
                //     // Handle 504 Gateway Timeout error
                //     if (fillUrl) {
                //         showToast('success', 'Files are uploaded successfully.')
                //         return await Promise.resolve()
                //     } else {
                //         showToast(
                //             'error',
                //             'Gateway Timeout: The server did not respond in time.',
                //         )

                //         return await Promise.reject(
                //             new Error('Gateway Timeout'),
                //         )
                //     }
                // }

                return await response.json()
            })
            .then(async response => {
                if (response.code === 401) {
                    localStorage.removeItem('token')
                    queryCache.clear()
                    showToast('error', response.message)
                    window.location.reload()
                    throw new Error('Please re-authenticate.')
                }

                if (response.code === 400) {
                    // showToast('error', response.message)
                    throw new Error(response.message)
                }
                if (response.code === 403) {
                    // showToast('error', response.message)
                    throw new Error(response.message)
                }
                if (config.method === 'POST') return response
                if (response?.body) {
                    return response?.body
                }
            })
            .catch(async error => {
                throw new Error(error?.message)
            })
    } catch (error: any) {
        showToast('error', error?.message)

        return await Promise.reject(error)
    }
}

export { client }
