import React, { createContext, useContext, useState } from 'react'
import './App.css'
import {
    ChakraProvider,
    Spinner,
    createStandaloneToast,
} from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Provider } from 'jotai'
import AppRoutes from 'routes'

import theme from 'assets/theme'
import 'react-multi-email/dist/style.css'
import 'react-datepicker/dist/react-datepicker.css'
export const { ToastContainer, toast } = createStandaloneToast()

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 60 * 20,
        },
    },
})

export const LoadingContext = createContext({
    isLoading: false,
    startLoading: () => {},
    stopLoading: () => {},
})

export const useLoading = () => {
    return useContext(LoadingContext)
}

function App() {
    const [isLoading, setIsLoading] = useState(false)

    const startLoading = () => {
        setIsLoading(true)
    }

    const stopLoading = () => {
        setIsLoading(false)
    }
    return (
        <Provider>
            <GoogleOAuthProvider clientId="440381447931-tsevjslcpuu23qo81b8o25fone7r3o21.apps.googleusercontent.com">
                <QueryClientProvider client={queryClient}>
                    <ChakraProvider theme={theme}>
                        <LoadingContext.Provider
                            value={{ isLoading, startLoading, stopLoading }}
                        >
                            <div style={{ position: 'relative' }}>
                                {isLoading && (
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.8)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 999,
                                        }}
                                    >
                                        <Spinner size="xl" color="blue.500" />
                                    </div>
                                )}
                                <AppRoutes />
                            </div>
                        </LoadingContext.Provider>
                    </ChakraProvider>
                    <ToastContainer />
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </GoogleOAuthProvider>
        </Provider>
    )
}

export default App
