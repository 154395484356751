export default {
    variants: {
        primary: {
            field: {
                background: 'white',
                rounded: '10px',
                border: '1px solid #CCC',
                fontSize: '14px',
            },
        },
    },
}
