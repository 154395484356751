import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import colors from './colors'
import styles from './styles'
import components from './components'

const theme = {
    components,
    colors,
    styles,
}

export default extendTheme(
    theme,
    withDefaultColorScheme({
        colorScheme: 'primary',
    }),
)
