import { Box, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React from 'react'
import { userAtom } from 'store'
import { formatDate } from 'utils/formatDateTime'
import { removeHtmlTags } from 'utils/helper'
const NotesCard = ({ item }: any) => {
    const [userDetials] = useAtom(userAtom)
    return (
        <>
            <Box
                backgroundColor="#fdfdfd"
                marginTop="20px"
                border="1px solid #ccc"
                padding="10px"
                borderRadius="5px"
                boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Box>
                            <Text paddingRight="5px">
                                <span style={{ fontWeight: 'bold' }}>Note</span>
                                <span
                                    style={{
                                        fontSize: '16px',
                                        color: '#666',
                                        marginLeft: '3px',
                                    }}
                                >
                                    by
                                </span>
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="16px" textColor="#666">
                                <span>{userDetials?.firstName}</span>
                                <span style={{ marginLeft: '5px' }}>
                                    {userDetials?.lastName}
                                </span>
                            </Text>
                        </Box>
                    </Flex>
                    <Flex alignItems="center">
                        <Box paddingLeft="5px">
                            <Text fontSize="16px" textColor="#666">
                                {formatDate(new Date(item.createdAt))}
                            </Text>
                        </Box>
                    </Flex>
                </Flex>

                <Box marginTop="10px" paddingX="18px">
                    <Text fontSize="16px" textColor="#666">
                        {removeHtmlTags(item.content)}
                    </Text>
                </Box>
            </Box>
        </>
    )
}
export default NotesCard
