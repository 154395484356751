import { Box, Button, Flex } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React from 'react'
import CreateTaskModal from './component/TaskModal'
import SingleTask from './component/SingleTask'
import { createTaskModalAtom } from 'store/modals'
import { useGetTasks } from 'utils/api/contacts.api'

// import { useGetNotes } from 'utils/api/contacts.api'

const TaskTab = () => {
    const [taskModal, setTaskModal] = useAtom(createTaskModalAtom)

    const { data: tasksList, isLoading } = useGetTasks()
    // console.log('notesList', notesList)
    //fetch data
    // const data = [
    //     {
    //         name: 'umar abbas',
    //         date: new Intl.DateTimeFormat('en-US', {
    //             year: 'numeric',
    //             month: 'long',
    //             day: 'numeric',
    //             hour: 'numeric',
    //             minute: 'numeric',
    //             hour12: true,
    //         }).format(new Date()),
    //         note: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged',
    //     },
    //     {
    //         name: 'umar abbas',
    //         date: new Intl.DateTimeFormat('en-US', {
    //             year: 'numeric',
    //             month: 'long',
    //             day: 'numeric',
    //             hour: 'numeric',
    //             minute: 'numeric',
    //             hour12: true,
    //         }).format(new Date()),
    //         note: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged',
    //     },
    // ]
    return (
        <Box>
            <Flex justifyContent="end">
                <Button
                    onClick={() => {
                        setTaskModal({
                            status: true,
                            data: '',
                        })
                    }}
                >
                    Create Task
                </Button>
            </Flex>

            {tasksList?.map((item: any) => {
                return <SingleTask key={item?._id} item={item} />
            })}

            <CreateTaskModal
                isOpen={taskModal.status}
                onClose={() => {
                    setTaskModal({
                        status: false,
                        data: '',
                    })
                }}
            />
        </Box>
    )
}

export default TaskTab
