import { useEffect } from 'react'

export const useLoadGoogle = (onLoadCallback: any) => {
    const loadGoogleApi = () => {
        return new Promise(resolve => {
            //@ts-ignore
            window?.gapi?.load('client:auth2', () => {
                resolve('ok')
            })
        })
    }

    useEffect(() => {
        const initializeGoogleApi = async () => {
            await loadGoogleApi()
            //perform action when react here
            if (onLoadCallback && typeof onLoadCallback === 'function') {
                onLoadCallback()
            }
        }
        initializeGoogleApi()
    }, [onLoadCallback])
}
