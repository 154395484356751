import { atom } from 'jotai'

const createModalAtom = () => {
    const modalAtom = atom<{ status: boolean; data: any }>({
        status: false,
        data: null,
    })

    return modalAtom
}

export const emailModalAtom = createModalAtom()
export const createNoteModalAtom = createModalAtom()
export const createCallModalAtom = createModalAtom()
export const createTaskModalAtom = createModalAtom()
export const createMeetingModal = createModalAtom()
export const connectEmailModalAtom = createModalAtom()

// export const currenySignAtom = atom({
//     currencySign: '€',
//     currencyName: 'EUR',
//     countryCode: 'nl',
// })
