export default {
    baseStyle: {
        borderRadius: '10px',
    },
    variants: {
        ghost: {
            _hover: {
                background: 'transparent',
            },
        },
        solid: {
            _active: {
                background: 'transparent',
            },
        },
        primary: {
            background: 'white',
            _active: {
                background: 'transparent',
            },
        },
        iconBtn: {
            background: '#0524600D',
            rounded: '50%',
            minW: '30px',
            minH: '30px',
            maxW: '30px',
            maxH: '30px',
            p: 0,
            '.chakra-button__icon': {
                m: 0,
            },
        },
    },
}
