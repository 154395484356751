export const SIDEBAR_MENU = [
    {
        path: 'connectAccounts',
        label: 'Connect',
    },
    {
        path: 'contacts',
        label: 'Contacts',
    },
    {
        path: 'emailing',
        label: 'Emailing',
    },
    {
        label: 'Fundraising',
        childpaths: [
            {
                path: 'fundraising',
                label: 'Fundraising',
            },
            {
                path: 'deals',
                label: 'Deals',
            },
            {
                path: 'forecast',
                label: 'Forecast',
            },
            {
                path: 'tasks',
                label: 'Tasks',
            },
            {
                path: 'callOutcomes',
                label: 'Call Outcomes',
            },
            {
                path: 'chats',
                label: 'Chats',
            },
            {
                path: 'completedActivities',
                label: 'Completed Activities',
            },
        ],
    },
]
