import React from 'react'
import { Box } from '@chakra-ui/react'
import 'react-quill/dist/quill.snow.css'
import NumberDisplay from './NumberDisplay'
import NumberPad from './NumberPad'
import ContactList from './ContactList'
import CommonModal from 'components/CommonModal'

interface CallsModalProps {
    isOpen: boolean
    onClose: () => void
}

const CreateCallsModal: React.FC<CallsModalProps> = ({
    isOpen,
    onClose,
}: CallsModalProps) => {
    return (
        <CommonModal
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            modalBodyPadding="0px"
        >
            <Box
                background="steelblue"
                // bg="linear-gradient(135deg, #251285 0%, #3D5AFE 45.42%, #04E7E7 100%)"
                height="43.5rem"
                paddingTop="3rem"
                overflow="hidden"
                borderRadius="5px"
            >
                <NumberDisplay />
                <NumberPad />
                <Box height="60%" overflow="scroll">
                    <ContactList />
                </Box>
            </Box>
        </CommonModal>
    )
}

export default CreateCallsModal
